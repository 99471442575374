<template>
    <section class="px-4 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 76px)">
        <div class="row mx-0 mb-4">
            <p class="col-12 px-0 text-black f-600 f-28">
                Invita y gana
            </p>
            <div class="like-bar  mt-1" />
        </div>
        <div class="row mx-0 my-4">
            <div class="col-3">
                <div class="btn-invita-gana d-middle pl-4 position-relative" @click="irA('invita-gana')">
                    <span class="font-omnes f-22 text-white lh-21">Comparte <br /> y gana</span>
                    <img src="/img/ilustraciones/gaming/grupo_recompensas.png" class="position-absolute" height="110" style="right:30px;max-width: 100%;top:-27px" />
                </div>
                <div class="bg-white br-8 shadow-0D mt-3 py-2">
                    <p class="text-center text-general f-17">
                        Compartir mi código
                    </p>
                    <p id="compartirCodigo" class="text-center text-general f-30 f-600 mt-2">
                        {{ usuario.id }}
                    </p>
                    <!-- <el-input v-model="usuario.id" class="text-center text-general f-30 f-600 mt-2" /> -->
                    
                    <div class="row mx-0 justify-content-center my-2">
                        <div class="col-auto">
                            <button type="button" class="btn-copiar" @click="copyToClipBoard">
                                Copiar
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="referidoPor == null" class="bg-white br-8 shadow-0D mt-3 py-2">
                    <p class="text-center text-general f-17 mt-2">
                        Fui referido por
                    </p>
                    <p class="text-center text-general f-24 f-600 mt-2">
                        Sin asignar
                    </p>
                    <div class="row mx-3 mt-2">
                        <div class="col-12 d-middle bg-white br-20 border px-0">
                            <div class="col pl-0">
                                <el-input v-model="codigoBusqueda" prefix-icon="icon-search" class="input-transparent br-20 w-100" placeholder="Buscar" />
                            </div>
                            <div class="btn-general br-20 px-2" @click="referidoEspecifico">
                                Buscar
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="bg-white br-8 shadow-0D mt-3 py-2">
                    <p class="text-center text-general f-17 mt-2">
                        Fui referido por
                    </p>
                    <div class="row mx-0 align-items-center mb-4 ml-4">
                        <img :src="referidoPor.imagen" class="rounded-circle border" width="48" height="48" />
                        <div class="col px-3 text-general f-18 font-weight-bold">
                            {{ referidoPor.nombre }}
                        </div>
                    </div>
                    <div class="row mx-3 mt-2">
                        <div class="col-12 d-middle bg-white br-20 border px-0">
                            <div class="col pl-0">
                                <el-input v-model="codigoBusqueda" prefix-icon="icon-search" class="input-transparent br-20 w-100" placeholder="Buscar" />
                            </div>
                            <div class="btn-general br-20 px-2" @click="referidoEspecifico">
                                Buscar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto px-5" />
            <div v-if="listaReferidos.length === 0" class="col-4">
                <p class=" text-center text-general f-28 mb-4">
                    Tus referidos recientes
                </p>
                <div class="bg-white br-8 shadow-0D mt-3 py-2">
                    <p class="text-center text-general f-17 mt-2">
                        Aún no tienes referidos, comparte tu código para tener grandes beneficios.
                    </p>
                </div>
            </div>
            <div v-else class="col-4">
                <p class="text-general f-28 mb-4">
                    Tus referidos recientes
                </p>
                <div v-for="data in listaReferidos" :key="data.id" class="row mx-0 align-items-center mb-4">
                    <img :src="data.imagen" class="rounded-circle border" width="48" height="48" />
                    <div class="col px-3 text-general f-18">
                        {{ data.nombre }}
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-auto px-4" />
                    <div class="col-auto text-purple f-18" @click="mostrarReferidos">
                        Ver todos
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <p class="col-12 text-general f-22 f-600">
                Gana gemas y monedas para redimir en compras y premios
            </p>
            <div class="col-auto px-4 ml-3 mt-3 br-5 bg-white d-flex py-3 mb-3">
                <img src="/img/ilustraciones/gaming/grupo_recompensas.png" width="100" height="100" />
                <div class="col-auto">
                    <p class="text-gris2 f-16 f-600">
                        Por cada nuevo <br /> referido ganarás:
                    </p>
                    <div class="row mx-0 mt-2">
                        <div class="col-auto d-middle px-0">
                            <img src="/img/ilustraciones/gaming/i_moneda.svg" width="30" height="30" />
                            <span class="mx-2 text-grey f-16">
                                20
                            </span>
                        </div>
                        <div class="col-auto d-middle px-0">
                            <img src="/img/ilustraciones/gaming/i_gema.svg" width="30" height="30" />
                            <span class="mx-2 text-grey f-16">
                                5
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <p class="text-gris2 f-16 f-600">
                        Por la primera compra de <br /> cada referido ganarás:
                    </p>
                    <div class="row mx-0 mt-2">
                        <div class="col-auto d-middle px-0">
                            <img src="/img/ilustraciones/gaming/i_moneda.svg" width="30" height="30" />
                            <span class="mx-2 text-grey f-16">
                                20
                            </span>
                        </div>
                        <div class="col-auto d-middle px-0">
                            <img src="/img/ilustraciones/gaming/i_gema.svg" width="30" height="30" />
                            <span class="mx-2 text-grey f-16">
                                5
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <p class="text-gris2 f-16 f-600">
                        Por el valor de la compra <br /> de cada referido ganarás:
                    </p>
                    <div class="row mx-0 mt-2">
                        <div class="col-auto d-middle px-0">
                            <img src="/img/ilustraciones/gaming/i_moneda.svg" width="30" height="30" />
                            <span class="mx-2 text-grey f-16">
                                20
                            </span>
                        </div>
                        <div class="col-auto d-middle px-0">
                            <img src="/img/ilustraciones/gaming/i_gema.svg" width="30" height="30" />
                            <span class="mx-2 text-grey f-16">
                                5
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalReferidos" titulo="Referidos" no-adicional no-cancelar>
            <div v-for="datas in listaReferidosCompleta" :key="datas.id" class="row mx-0 j-center align-items-center mb-4">
                <img :src="datas.imagen" class="rounded-circle border" width="48" height="48" />
                <div class="col-5 px-3 text-general f-18">
                    {{ datas.nombre }} 
                </div>
            </div>
        </modal>
        <modal ref="modalReferidoPor" titulo="Referido por:" adicional="Seleccionar" @adicional="agregarReferidoPadre">
            <div v-if="userPadre != null" class="row mx-0 j-center align-items-center">
                <img :src="userPadre.imagen" class="rounded-circle border obj-cover" width="142" height="142" />
                <div class="col-12 text-center px-3 text-general f-18 mt-3">
                    {{ userPadre.nombre }}
                </div>
            </div>
            <div v-else class="row mx-0 j-center align-items-center">
                <div class="col-12 text-center px-3 text-general f-18 mt-3">
                    <span class="text-danger w-100 f-15 row mx-0 j-center">Lo sentimos, no se encontraron resultados</span>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Referido from '~/services/referido/referido'
import {mapGetters} from 'vuex'
export default {
    middleware:'menu-check',
    data(){
        return {
            referidoPor: {},
            userPadre:{},
            listaReferidos: [],
            listaReferidosCompleta: [],
            limitLista: 5,
            codigoBusqueda: ''
        }
    },
    computed:{
        ...mapGetters({
            usuario: 'auth/obtenerUsuario',
        })
    },

    mounted(){
        this.referidoPadre();
        this.referidosLista();
    },
    methods: {
        mostrarReferidos(){
            this.limitLista = null;
            this.referidosLista();
            this.$refs.modalReferidos.toggle();
        },
        referidoEspecifico(){
            if(this.codigoBusqueda.length >= 1){
                this.buscarReferidoPadre()
                this.$refs.modalReferidoPor.toggle();
            }
        },
        async referidoPadre(){
            try {
                const { data } = await Referido.getDataReferidoPadre();
                this.referidoPor = data.referidoPor;
            } catch (e){
                this.errorCatch(e)
            }          
        },
        async referidosLista(){
            try {
                let params = {
                    limit: this.limitLista
                }
                const { data } = await Referido.getListaReferidos(params);

                if( data.listaReferidos != null){
                    this.listaReferidos = data.listaReferidos;
                }else{
                    this.listaReferidosCompleta = data.listaReferidosCompleta
                }

            } catch (e){
                this.errorCatch(e)
            }    
        },
        async buscarReferidoPadre(){
            try{
                let params = {
                    id_padre : this.codigoBusqueda
                }
                const { data } = await Referido.buscarReferidoPadre(params);
                this.userPadre = data.userPadre
            } catch (e){
                this.errorCatch(e)
            }    
        },
        async agregarReferidoPadre(){
            try{
                let payload = {
                    id_padre : this.userPadre.id_user
                }
                const { data } = await Referido.agregarReferidoPadre(payload);
                this.notificacion('Exito', 'Usuario agregado','success');
                this.userPadre = {}
                this.codigoBusqueda = ''
                this.referidoPadre();
                this.$refs.modalReferidoPor.toggle();
            } catch (e){
                this.errorCatch(e)
            }  
        },
        copyToClipBoard(){
            try{
                var aux = document.createElement("input");
                aux.setAttribute("value", document.getElementById('compartirCodigo').innerHTML);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand('copy');
                document.body.removeChild(aux);
                this.notificacion('Exito', 'código copiado al portapapeles','success');
            } catch (e){
                this.errorCatch(e)
            }  
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-invita-gana{
    border-radius: 12px;
    height: 80px;
     background-image: radial-gradient(closest-corner at 73% 62%,#CDB1FF, #A880FF, #7D4AE8);
}
.btn-copiar{
    width: 139px;
    height: 28px;
    border: 1px solid #DBDBDB;
    border-radius: 16px;
    &:focus{
        outline: none;
    }
}
</style>