<template>
    <section class="section-creditos overflow-auto custom-scroll pt-4 pb-4 px-4" style="height:calc(100vh - 77px)">
        <cargando v-if="cargando" />
        <div class="row mx-0 mb-5 px-xl-4 px-lg-4 px-md-0 px-sm-0 px-0">
            <div class="col-auto">
                <p class="f-30 text-general f-600">
                    Créditos
                </p>
                <div class="like-bar mt-2" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-auto px-xl-4 px-lg-4 px-md-0 px-sm-0 px-0" />
            <div class="col-xl col-lg col-md col-sm-12 col-12">
                <div class="row mx-0 mb-3 pr-4">
                    <div class="col-auto px-0">
                        <p class="f-28 text-general f-600">
                            Deuda
                        </p>
                        <div class="like-bar mt-2" />
                    </div>
                </div>
                <div class="row mx-0 mt-3 mb-5 a-center px-3 text-general border-red br-4" style="height: 48px;background-color:#FF17201A;">
                    <div class="col-auto px-0 text-general f-16">
                        Total a pagar
                    </div>
                    <div class="col-auto px-0 text-general f-17 f-600 ml-auto">
                        {{ formatNumero(total_pagar) }}
                    </div>
                </div>
                <div v-if="total_pagar == 0" class="row mx-0 j-center mt-3">
                    <div class="col-auto px-2">
                        <img :src="funImagenGeneral(27)" width="250" style="max-height:100%;" />
                        <p class="text-center f-17 mt-4">
                            Estás al día con tus créditos
                        </p>
                    </div>
                </div>
                <div v-for="sec in creditos" v-else :key="sec.id" class="section-deuda mb-5">
                    <div class="row mx-0 a-center pr-1 mb-3 bg-fondo2 br-20 text-general" style="height:35px;">
                        <p v-if="sec.registrosVigentes" class="col-auto f-15 f-400">
                            Proximos pagos
                        </p>
                        <p v-else class="col-auto f-15 f-400">
                            Pagos atrasados
                        </p>
                        <p class="col-auto f-15 text-general ml-auto f-600">
                            {{ formatNumero(sec.total) }} 
                        </p>
                        <div class="bg-black rounded-circle text-white f-14 text-center" style="width:20px;height:20px;">
                            {{ sec.cantidad }}
                        </div>
                    </div>
                    <div v-for="data in sec.registrosVigentes" :key="`dwn-${data.id}`" class="card-credito bg-white cr-pointer shadow-14 px-2 pt-1 br-8 mb-2" @click="verCredito(data.id_pedido)">
                        <div class="row mx-0 a-center pt-1">
                            <i class="icon-coin text-general f-18" />
                            <div class="col-auto px-2 text-general">
                                cuota: 
                                <b class="text-red f-500">
                                    {{ formatNumero(data.valor_pendiente) }}
                                </b>
                            </div>
                            <div class="col-auto px-2 text-general f-300 f-15 ml-auto">
                                {{ formatearFecha(data.fecha, formatoDestino='DD/MM/YYYY') }}
                            </div>
                        </div>
                        <div class="row mx-0 a-center pt-3">
                            <i class="icon-order text-general f-18" />
                            <div class="col-auto px-2 f-300 text-general">
                                No. {{ data.id_pedido }}
                            </div>
                            <div class="col-auto px-2 text-general f-500 f-15 ml-auto">
                                {{ formatNumero(data.total_pedido) }}
                            </div>
                        </div>
                    </div>
                    <div v-for="data in sec.registrosVencidos" :key="`dwn-${data.id}`" class="card-credito bg-white cr-pointer shadow-14 px-2 pt-1 br-8 mb-2" @click="verCredito(data.id_pedido)">
                        <div class="row mx-0 a-center pt-1">
                            <i class="icon-coin text-general f-18" />
                            <div class="col-auto px-2 text-general">
                                cuota:  
                                <b class="text-red f-500">
                                    {{ formatNumero(data.valor_pendiente) }}
                                </b>
                            </div>
                            <div class="col-auto px-2 text-general f-300 f-15 ml-auto">
                                {{ formatearFecha(data.fecha, formatoDestino='DD/MM/YYYY') }}
                            </div>
                        </div>
                        <div class="row mx-0 a-center pt-3">
                            <i class="icon-order text-general f-18" />
                            <div class="col-auto px-2 f-300 text-general">
                                No. {{ data.id }}
                            </div>
                            <div class="col-auto px-2 text-general f-500 f-15 ml-auto">
                                {{ formatNumero(data.total_pedido) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl col-lg col-md col-sm-12 col-12">
                <div class="row mx-0 mb-3 pr-4 pl-0">
                    <div class="col-auto pl-0">
                        <p class="f-28 text-general f-600">
                            Historial
                        </p>
                        <div class="like-bar mt-2" />
                    </div>
                </div>
                <div class="section-historial">
                    <div class="row mx-0 a-center pr-1 mb-3 bg-fondo2 br-20 text-general" style="height:35px;">
                        <p class="col-auto f-15 text-general mr-auto f-600">
                            Créditos
                        </p>
                        <div class="bg-black rounded-circle text-white f-14 text-center ml-auto" style="width:20px;height:20px;">
                            {{ historialCont }}
                        </div>
                    </div>
                    <div v-for="data in creditosHistorial" :key="data.id" class="card-historial f-15 bg-white py-2 px-2 shadow-14 cr-pointer pt-1 br-8 mb-3" @click="verCredito(data.id_pedido)">
                        <div class="row mx-0 pt-1">
                            <img v-if="data.pago_estado == 3" src="/img/ilustraciones/cash.svg" width="30" height="30" />
                            <i v-else-if="data.pago_estado == 2" class="icon-card text-general f-18" />
                            <i v-else-if="data.pago_estado == 4" class="icon-credit-cancel f-18 text-red" />
                            <div class="col px-2 pt-1">
                                <div class="row mx-0 ml-auto">
                                    <div v-if="data.pago_estado == 3" class="col-auto f-600 f-600 px-0 text-general">
                                        Crédito Pagado
                                    </div>
                                    <div v-else-if="data.pago_estado == 2" class="col-auto f-600 f-600 px-0 text-general">
                                        Nuevo crédito
                                    </div>
                                    <div v-else-if="data.pago_estado == 4" class="col-auto f-600 f-600 px-0 text-general">
                                        Crédito  anulado
                                    </div>
                                    <div class="col-auto px-2 text-general f-600 f-15 ml-auto">
                                        {{ formatNumero(data.total_credito - data.total_saldo) }}
                                    </div>
                                </div>
                                <div class="row mx-0 ml-auto mt-2">
                                    <div v-if="data.total_saldo == 0" class="col-auto px-0 text-general mr-5">
                                        Pago efectivo
                                    </div>
                                    <div class="col-auto px-0 f-15 f-500 px-2 text-general">
                                        {{ formatearFecha(data.fecha ) }}
                                    </div>
                                </div>
                                <p class="text-general f-15 mt-1">
                                    Saldo Pendiente: <span class="text-red f-500 ml-2"> {{ formatNumero(data.total_saldo) }}</span>
                                </p>
                            </div>
                        </div>
                        <hr class="my-2 border" />
                        <div class="row mx-0 a-center">
                            <i class="icon-order text-general f-18" />
                            <div class="col-auto px-2 text-general">
                                No. {{ data.id_pedido }}
                            </div>
                            <div class="col-auto px-2 text-general f-600 f-15 ml-auto">
                                {{ formatNumero(data.total_credito) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section-footer class="mt-3" />
        <!-- Partials -->
    </section>
</template>

<script>
import Creditos from '~/services/creditos/creditos'
export default {
    middleware:'menu-check',
    data(){
        return{
            cargando: false,
            creditos: [],
            creditosHistorial: [],
            total_pagar: null,
            historialCont: null
        }
    },
    mounted(){
        this.creditosVigentes();
        this.Historialcreditos();
    },
    methods: {
        verCredito(item){
          
            this.$router.push({name: 'creditos.deudas.ver', params: { id_credito: item } })
        },
        async creditosVigentes(){
            try {
                this.cargando = true;

                const { data } = await Creditos.getCreditosVigentes();
                this.creditos.push(data.vigentes);
                this.creditos.push(data.vencidos);
                this.total_pagar = data.total_pagar;
            } catch (e){
                this.errorCatch(e)
            } finally{
                this.cargando = false;
            }
        },

        async Historialcreditos(){
            try {
                this.cargando = true;

                const { data } = await Creditos.getCreditosHistorial();
                this.creditosHistorial = data.historial
                this.historialCont = this.creditosHistorial.length;
            } catch (e){
                this.errorCatch(e)
            } finally{
                this.cargando = false;
            }    
        },

    }
}
</script>
<style lang="scss" scoped>
.box{
    width: 567px;
    height: calc(100% - 1px);
    box-shadow: 0px 1px 4px #00000014 !important;
    border-radius: 12px 12px 0px 0px !important;
}
@media (max-width: 768px){
    .section-creditos{
        height: calc(100vh - 167px) !important;
    }
}
</style>