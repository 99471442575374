<template>
    <section class="section-soporte bg-fondo  pt-3 custom-scroll overflow-auto" style="">
        <div class="px-4">
            <cargando v-if="cargando" />
            <div class="row mx-0">
                <div class="col-auto pr-0">
                    <div class="row mx-0 mb-4">
                        <p class="col-12 px-0 text-black f-600 f-28">
                            Ayuda
                        </p>
                        <div class="like-bar  mt-1" />
                    </div>
                </div>
                <div class="col-xl col-lg col-md-12 col-sm-12 col-12">
                    <p class="text-general f-28 mb-5">
                        ¿Cómo podemos ayudarte?
                    </p>
                    <div class="row mx-0 mb-5">
                        <div class="col-12 col-md-6 px-0 mb-3 mb-lg-0">
                            <p class="text-general f-28 f-500 mb-1 mb-lg-3">
                                Tu último pedido
                            </p>
                            <div class="row mx-0">
                                <div v-if="pedidos.length > 0" class="col-12 pl-lg-0">
                                    <card-ult-pedido v-for="(pedido, index) in pedidos" :key="index" :pedido="pedido" @update="getData" />
                                </div>
                                <div v-else class="col-12 pl-lg-0">
                                    <p class="d-flex text-gris2 f-500 f-15 mt-1">Actualmente no tienes pedidos</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ml-0 mb-3 mb-lg-0 pl-0 pl-xl-3">
                            <p class="text-general f-28 f-500 mb-1 mb-lg-3">
                                Últimas consultas
                            </p>
                            <!-- consulta activa -->
                            <div v-if="consultas.activa" class="card-consulta cr-pointer p-2 bg-white br-10 shadow-14 mb-3" @click="goMessagesConsult('active')">
                                <div class="row mx-0 mt-1">
                                    <div class="active-circle mt-1" />
                                    <div class="col px-2 f-15">
                                        <p class="f-600"> Consulta</p>
                                        <div class="d-flex text-gris2 f-500 f-15 mt-1">
                                            <span>{{ consultas.activa.created_at | helper-fecha('D MMMM YYYY') }}</span>
                                            <span class="ml-auto">Abierto</span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-middle text-gris2">
                                        <i class="icon-chat" />
                                        <span>{{ consultas.activa.total_mensajes }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- consultas finalizadas -->
                            <div v-if="consultas.final" class="card-consulta cr-pointer p-2 bg-white br-10 shadow-14 mb-3" @click="goMessagesConsult('finished')">
                                <div class="row mx-0 mt-1">
                                    <i class="icon-ok-circled-outline text-gris f-15" />
                                    <div class="col px-2 f-15">
                                        <p class="f-600"> Consulta</p>
                                        <div class="d-flex text-gris2 f-500 f-15 mt-1">
                                            <span>{{ consultas.final.created_at | helper-fecha('D MMMM YYYY') }}</span>
                                            <span class="ml-auto">Finalizada</span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-middle text-gris2">
                                        <i class="icon-chat" />
                                        <span>{{ consultas.final.total_mensajes }}</span> 
                                    </div>
                                </div>
                            </div>
                            <div v-if="!consultas.activa && !consultas.final" class="row mx-0 mt-1">
                                <div class="col px-lg-2 f-15">
                                    <p class="d-flex text-gris2 f-500 f-15 mt-1">Actualmente no tienes consultas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-5">
                        <div class="col-12 col-lg-6 px-0">
                            <p class="text-general f-28 f-500 mb-3">
                                Preguntas frecuentes
                            </p>
                            <template v-if="preguntas.length > 0">
                                <div v-for="(general, index) in preguntas" :key="index">
                                    <p class="text-general f-600 f-17 mb-2">
                                        {{ general.titulo }}
                                    </p>
                                    <div v-for="(pregunta, index1) in general.preguntas" :key="index1" class="row mx-0" @click="goShowResponse(pregunta.id)">
                                        <div class="col-12 px-0 d-middle bg-white header-question px-3 cr-pointer mb-3">
                                            {{ pregunta.pregunta }} <i :class="`icon-${showRespuesta.id === pregunta.id ? 'up-open' : 'down-open'} ml-auto`" />
                                        </div>
                                        <div v-show="showRespuesta.id === pregunta.id" class="col-12 text-gris2 f-14 mb-2" style="white-space:pre-line;">
                                            {{ showRespuesta.texto }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row mx-0">
                                    <div class="col">
                                        <p class="d-flex text-gris2 f-500 f-15 mt-1">Actualmente no hay preguntas frecuentes</p>
                                    </div>
                                </div>
                            </template>
							
                            <div class="row mx-0 my-3 j-center">
                                <div class="btn-consultar cr-pointer f-16 d-middle-center px-5 f-500" @click="goConsulta">
                                    <i class="icon-suport f-18 mr-2" /> Consultar un asesor
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5">
                            <p class="text-general f-28 f-500 mb-3">
                                Políticas y términos
                            </p>
                            <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <div class="row-politics cr-pointer d-middle px-2 mb-2" @click="goCondiciones">
                                        <i class="icon-book-open-variant f-16" />
                                        <div class="col tres-puntos px-2 f-17 text-general">
                                            Términos y condiciones
                                        </div>
                                        <i class="icon-right-open f-16" />
                                    </div>
                                    <div class="row-politics cr-pointer d-middle px-2" @click="goPoliticas">
                                        <i class="icon-lock f-16" />
                                        <div class="col tres-puntos px-2 f-17 text-general">
                                            Política de privacidad
                                        </div>
                                        <i class="icon-right-open f-16" />
                                    </div>
                                </div>
                            </div>
                            <!-- <p class="text-general f-28 f-500 mb-3 mt-5">
								Social
							</p>
							<div class="row mx-0">
								<div v-for="(red,index) in redesIconos" :key="index" class="bg-white br-12 p-2 mr-2">
									<a :href="red.url" target="_blank">
										<img :src="red.icon" />
									</a>
								</div>
							</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section-footer class="footer-soporte mt-3 mt-lg-0" />
        <!-- Partials -->
        <modal-consulta ref="modalConsulta" @update="initConsult" />
        <modalChatConsulta ref="modalChatConsulta" />
    </section>
</template>
<script>
import Ayuda from '@/services/ayuda/ayuda'
export default {
    components: {
        cardUltPedido: () => import('./components/cardUltPedido'),
        modalConsulta: () => import('./partials/modalConsulta'),
        modalChatConsulta: () => import('./partials/modalChatConsulta')
    },
    data(){
        return {
            cargando: false,
            pedidos: [],
            consultas: {},
            preguntas: [],
            respuestas: [],
            showRespuesta: {
                id: null,
                texto: null
            },
            redesIconos: [
                {tipo: 31, icon: "/img/icons/facebook.svg"},
                {tipo: 32, icon: "/img/icons/youtube.svg"},
                {tipo: 33, icon: "/img/icons/instagram.svg"},
            ]
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.cargando = true;

                const { data } = await Ayuda.getData();
                this.pedidos = data.pedidos;
                this.consultas = data.consultas;
                this.preguntas = data.preguntas.preguntas;
                this.respuestas = data.preguntas.respuestas;
            } catch(e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }
        },

        goShowResponse(idPregunta){
            let object = this.respuestas.find((item) => parseInt(item.id_faq) === parseInt(idPregunta));

            if(parseInt(this.showRespuesta.id) === parseInt(object.id_faq)){
                this.showRespuesta.id = null; this.showRespuesta.texto = null; return
            }

            this.showRespuesta.id = object.id_faq;
            this.showRespuesta.texto = object.texto;
        },

        goCondiciones(){
            this.$router.push({name:'terminos-condiciones-cliente'});
        },

        goPoliticas(){
            this.$router.push({name:'politicas-privacidad-cliente'});
        },

        goConsulta(){
            if(this.consultas.activa){
                this.goMessagesConsult('active');
            } else {
                this.$refs.modalConsulta.toggle();
            }
        },

        initConsult(data){
            this.consultas = data;
        },

        goMessagesConsult(type){
            this.$refs.modalChatConsulta.toggle(type);
        },
    }
}
</script>
<style lang="scss" scoped>
.box{
    width: 567px;
    height: 84vh;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
    .status{
        width: 20px;
        height: 20px;
        background-color: #DBDBDB;
        border-radius: 50%;
        &.active{ background-color: var(--color-general) !important; }
    }
}
.shadow-14{
    box-shadow: 0px 1px 4px #00000014;
}
.card-consulta{
    height: 73px;
    min-width: 358px;
    max-width: 358px;
    .active-circle{
        width: 16px;
        height: 16px;
        background-color: #29D884;
        border-radius: 50%;
    }
}
.header-question{
    height: 40px;
    border-radius: 12px;
}
.row-politics{
    height: 40px;
    border-radius: 12px;
    background-color: #FFFFFF;
}
.btn-consultar{
    height: 44px;
    background-color: #FFFFFF;
    border: 1px solid #DFE4E8;
    border-radius: 12px;
}
.section-soporte{
	height:calc(100vh - 95px);
	.footer-soporte{
		position: absolute;
		bottom: 0px;
	}
}
@media (max-width: 985px){
	.footer-soporte{
		position: relative !important;
	}
}
@media (max-width: 768px){
    section.custom-scroll.overflow-auto{
        height: calc(100vh - 167px) !important;
    }
	.footer-soporte{
		position: relative !important;
	}
}
</style>