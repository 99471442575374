import axios from 'axios';

const API = 'gaming/premios';

const ENDPOINTS = {

    getListaPremios(params){
        return axios.get(`${API}/lista`, {params})
    },
    getDetallePremio(id_premio){
        return axios.get(`${API}/${id_premio}/detalle`,)
    },
    solicitarCanjePremio(params){
        return axios.get(`${API}/solicitar-canje`, {params} )
    }
};

export default ENDPOINTS;