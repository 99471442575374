<template>
    <section class="px-xl-5 px-lg-3 px-md-3 px-sm-3 px-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 84px)">
        <cargando v-if="cargando" />
        <div class="row mx-0 mb-4">
            <div class="col pl-0">
                <p class="f-28 text-general f-600">
                    Mis canjes de premios
                </p>
                <div class="w-100" />
                <div class="like-bar mb-2" />
            </div>
        </div>
        <div v-if="canjesEnProceso.length == 0 && canjesFinalizados.length == 0" class="row mx-0">
            <div class="d-middle-center" style="width:100%;height:55vh;">
                <div class="col-auto">
                    <div class="row mx-0 j-center">
                        <img :src="funImagenGeneral(26)" width="180" height="180" />
                    </div>
                    <p class="text-center mt-3">
                        Aún no tienes premios, sigue comprando para tener grandes recompensas
                    </p>
                    <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                        <div class="col-12 px-2">
                            <div class="btn-general" @click="$router.push({name:'home.tienda'})">
                                Ir a la Tienda
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row mx-0">
            <div class="col-auto px-xl-4 px-lg-4 px-md-4 px-sm-0 px-0" />
            <div class="col-xl-4 col-lg-5 col-md-7 col-sm-10 col-10">
                <div class="divider-name pr-2 mb-3">
                    <div class="col-auto px-2 f-12 f-600">
                        En proceso
                    </div>
                    <div class="col-auto px-0 f-600 ml-auto">
                        {{ cantEnProceso }}
                    </div>
                </div>
                <div v-for="data in canjesEnProceso" :key="`proceso-${data.id_canje}`" class="card-canje cr-pointer p-3 d-flex bg-white mb-3" @click="$router.push({name: 'mis-canjes.detalle', params: {id_canje : data.id_canje} })">
                    <img :src="data.imagen" width="90" height="90" />
                    <div class="col px-3">
                        <p class="f-12 f-600">
                            Canje No. {{ data.id_canje }}
                        </p>
                        <p class="f-12">
                            {{ data.nombre }}
                        </p>
                        <div class="row mx-0 a-center my-1">
                            <img v-if="data.monedas != 0" src="/img/ilustraciones/gaming/i_moneda.svg" width="25" height="25" />
                            <img v-else src="/img/ilustraciones/gaming/i_gema.svg" width="25" height="25" />
                            <div v-if="data.monedas != 0" class="col-auto px-2 f-600">
                                {{ formatNumero(data.monedas) }}
                            </div>
                            <div v-else class="col-auto px-2 f-600">
                                {{ formatNumero(data.gemas) }}
                            </div>
                        </div>
                        <div class="row mx-0 a-center my-1">
                            <i :class="`${estadoPedido(data.estado)}`" />
                            <div class="col-auto px-2 text-gris2 f-12">
                                {{ textoPedido(data.estado) }}
                            </div> 
                            <div class="col-auto px-2 text-gris2 f-12">
                                {{ formatearFecha(data.updated_at) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider-name pr-2 mb-3">
                    <div class="col-auto px-2 f-12 f-600">
                        Finalizados
                    </div>
                    <div class="col-auto px-0 f-600 ml-auto">
                        {{ cantFinalizada }}
                    </div>
                </div>
                <div v-for="data in canjesFinalizados" :key="`finalizado-${data.id_canje}`" class="card-canje cr-pointer p-3 d-flex bg-white mb-3" @click="$router.push({name: 'mis-canjes.detalle', params: {id_canje : data.id_canje} })">
                    <img :src="data.imagen" width="90" height="90" />
                    <div class="col px-3">
                        <p class="f-12 f-600">
                            Canje No. {{ data.id_canje }}
                        </p>
                        <p class="f-12">
                            {{ data.nombre }}
                        </p>
                        <div class="row mx-0 a-center my-1">
                            <img v-if="data.monedas != 0" src="/img/ilustraciones/gaming/i_moneda.svg" width="25" height="25" />
                            <img v-else src="/img/ilustraciones/gaming/i_gema.svg" width="25" height="25" />
                            <div v-if="data.monedas != 0" class="col-auto px-2 f-600">
                                {{ formatNumero(data.monedas) }}
                            </div>
                            <div v-else class="col-auto px-2 f-600">
                                {{ formatNumero(data.gemas) }}
                            </div>
                        </div>
                        <div class="row mx-0 a-center my-1">
                            <i :class="`${estadoPedido(data.estado)} text-gris2`" />
                            <div class="col-auto px-2 text-gris2 f-12">
                                {{ textoPedido(data.estado) }}
                            </div>
                            <div class="col-auto px-2 text-gris2 f-12">
                                {{ formatearFecha(data.updated_at) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CanjePremios from '~/services/gaming/gamingCanjes'
export default {
    middleware:'menu-check',
    data(){
        return {
            cargando: false,
            canjesEnProceso: [],
            canjesFinalizados: [],
            cantEnProceso: null,
            cantFinalizada: null
        }
    },
    mounted(){
        this.getHistorialCanjes();
    },
    methods: {
        async getHistorialCanjes(){
            try {
                this.cargando = true;

                const { data } = await CanjePremios.getHistorialPremios();
                this.canjesEnProceso = data.pedidosEnProceso;
                this.canjesFinalizados = data.pedidosFinalizados;
                this.cantEnProceso = this.canjesEnProceso.length;
                this.cantFinalizada = this.canjesFinalizados.length;
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }
        },
        estadoPedido(state){
            switch (state){
            case 1:
                return 'icon-order f-18 text-gris2';
            case 2:
                return 'icon-ok-circled-outline f-18 text-gris2';
            case 4:
                return 'icon-order-packed f-18 text-green';
            case 21:
                return 'icon-cancel-circled-outline f-18 text-gris2';
            }
        },
        textoPedido(state){
            switch (state){
            case 1:
                return 'Creado';
            case 2:
                return 'Confirmado';
            case 4:
                return 'Entregado';
            case 21:
                return 'Cancelado';
            }
        },

    }
}
</script>
<style lang="scss" scoped>
.divider-name{
    height: 33px;
    border: 1px solid #DFE4E8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-canje{
    height: 114px;
    border-radius: 8px;
    border: 1px solid #DFE4E8;

    &:hover{
        box-shadow: 0px 3px 6px #0000000D;
    }
}
</style>