<template>
	<section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 75px)">
		<slot>
			<router-view @save="saveTel" :telefono="model"/>
		</slot>
	</section>
</template>

<script>
export default {
	components: {
		modalErrorPass: () => import('./partials/modalErrorPass')
	},
	data() {
		return {
			model :{
				telefono: '',
				indicador: null
			}
		}
	},
	methods: {
		saveTel(item) {
			this.model.telefono = item.telefono;
			this.model.indicador = item.indicador;
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	width: 597px;
	height: 55vh;
	border-radius: 12px;
	box-shadow: 0px 1px 4px #00000014;

	.title {
		font-size: 37px !important;
		font-weight: 600;
	}
}

@media (min-height: 300px) and (max-height: 780px) {
	.box {
		height: 69vh !important;
	}
}

@media (min-width: 300px) and (max-width: 695px) {
	.box {
		height: calc(100% - 1px) !important;
		width: 100%;
		border-radius: inherit !important;
	}
}
</style>