import axios from 'axios';

const API = 'referidos';

const ENDPOINTS = {
    getDataReferidoPadre(){
        return axios.get(`${API}/padre`)
    },
    buscarReferidoPadre(params){
        return axios.get(`${API}/buscar-padre`, {params})
    },
    getListaReferidos(params){
        return axios.get(`${API}/lista-hijos`, {params})
    },
    agregarReferidoPadre(payload){
        return axios.put(`${API}/agregar-padre`, payload)
    },

};

export default ENDPOINTS;