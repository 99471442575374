import axios from 'axios'

const API = "ayuda"

const Ayuda = {
    getData(){
        return axios.get(`${API}/data`)
    },
    getMotivos(tipo){
        return axios.get(`${tipo}/motivos`);
    },
    putEstadoPedido(idPedido, model){
        return axios.put(`${API}/${idPedido}/pedido/estado`, model)
    },
    postConsulta(model){
        return axios.post(`${API}/nuevo/consulta`, model)
    },
    getChatSoporte(params){
        return axios.get(`${API}/chat`, {params});
    },
    postChatSoporte(model){
        return axios.post(`${API}/chat/save`, model);
    },
    getChatConsultas(params){
        return axios.get(`${API}/chat/consultas`, {params});
    },
    postChatConsulta(model){
        return axios.post(`${API}/chat-consulta/save`, model);
    }
}

export default Ayuda