<template>
    <section class="px-xl-5 px-lg-3 px-md-3 px-sm-3 px-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 84px)">
        <div class="row mx-0">
            <i class="icon-back cr-pointer f-22 mt-1" @click="$router.back()" />
            <div class="col px-xl-3 px-lg-3 px-md-2 px-sm-2 px-2">
                <div class="row mx-0 mb-4">
                    <div class="col pl-0">
                        <p class="f-28 text-general f-600">
                            Detalle del premio
                        </p>
                        <div class="w-100" />
                        <div class="like-bar mb-2" />
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-auto px-0 pt-1">
                        <img :src="premioDetalle.imagen" class="obj-cover br-8" width="266" height="266" />
                    </div>
                    <div class="col-xl col-lg col-md-12 col-sm-12 col-12 px-4 pl-xl-4 pl-lg-3 pl-md-3 pl-sm-0 pl-sm-0">
                        <p class="f-20 f-600 mt-3">
                            {{ premioDetalle.nombre }}
                        </p>
                        <p class="text-gris2 f-15 mt-2">
                            {{ premioDetalle.descripcion }}
                        </p>
                        <div class="row mx-0 mt-5">
                            <p class="col-12 px-0 f-600 f-20 mb-2">
                                Selecciona cómo desea canjearlo
                            </p>
                            <div :class="`select-coin${coinSelect == 1 ? '-active' : ''} bg-white`" @click="coinSelect = 1, verificarCoins()">
                                <img src="/img/ilustraciones/gaming/i_moneda.svg" width="25" height="25" />
                                <span class="mx-2 f-14 f-500">
                                    {{ formatNumero(premioDetalle.monedas) }}
                                </span>
                            </div>
                            <div :class="`select-coin${coinSelect == 2 ? '-active' : ''} ml-3 bg-white f-500`" @click="coinSelect = 2, verificarCoins()">
                                <img src="/img/ilustraciones/gaming/i_gema.svg" width="25" height="25" />
                                <span class="mx-2 f-14">
                                    {{ formatNumero(premioDetalle.gemas) }}
                                </span>
                            </div>
                        </div>
                        <div v-if="pagoPremio == false" class="col-6 pl-0 mt-2">
                            <div class="cobertura-alert d-middle">
                                <i class="icon-alert-circle-outline f-25" />
                                <div class="col px-0 f-12 tres-puntos">
                                    No tienes suficientes 
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 mt-5">
                            <p class="col-12 px-0 f-600 f-20 mb-2">
                                Dirección de entrega
                            </p>
                            <div class="col-7 px-0">
                                <el-select v-model="direccion" class="w-100" @change="verificarCobertura(direccion)">
                                    <el-option
                                    v-for="(item, idx) in direccionesUser"
                                    :key="item.id_direccion"
                                    :label="item.direccion"
                                    :value="idx "
                                    />
                                </el-select>
                            </div>
                            <div class="col-6 pl-0 mt-2">
                                <div v-if="cobertura == false" class="cobertura-alert d-middle">
                                    <i class="icon-alert-circle-outline f-25" />
                                    <div class="col px-0 f-12 tres-puntos">
                                        No tenemos con cobertura en tu área
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 mt-4">
                            <div class="col-3 pl-0">
                                <div class="btn-general px-5" @click="canjearPremio()">
                                    Canjear
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-auto col-lg-auto col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <button-recompensa show-image />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Premios from '~/services/gaming/gamingPremios'
export default {
    middleware:'menu-check',
    data(){
        return {
            coinSelect: null,
            value: '',
            premioDetalle: {},
            direccionesUser: [],
            id_premio: this.$route.params.id_premio,
            direccion: '',
            coinsUser: {},
            pagoPremio: null,
            cobertura: null,
            coinsPago: null,
            idCanje: null,
            idDireccion: null
        }
    },
    mounted(){
        this.detallePremio();
    },
    methods: {
        async detallePremio(){
            try {
                const { data } = await Premios.getDetallePremio(this.id_premio);
                this.premioDetalle = data.productoDetalle;
                this.direccionesUser = data.userDirecciones;
                this.direccion = 0;
                this.cobertura = data.userDirecciones[0].cobertura
                this.coinsUser = data.gemasMonedas
            } catch (e){
                this.errorCatch(e)
            }          
        },
        verificarCoins(){
            if(this.coinSelect == 1){
                if(this.coinsUser.monedas_actual >= this.premioDetalle.monedas){
                    this.coinsPago = this.premioDetalle.monedas
                    this.pagoPremio = true;
                }else{
                    this.pagoPremio = false;
                }
            }else if(this.coinSelect == 2){
                if(this.coinsUser.gemas_actual >= this.premioDetalle.gemas){
                    this.coinsPago = this.premioDetalle.gemas;
                    this.pagoPremio = true;
                }else{
                    this.pagoPremio = false;
                }
            } ;
        },
        verificarCobertura(item){
            if(this.direccionesUser[item].cobertura== true){
                this.cobertura = true
                this.id_direccion = this.direccionesUser[item].id_direccion;
            }else{
                this.cobertura = false
            }
        },
        async canjearPremio(){
            try{
                if(this.pagoPremio == true && this.cobertura == true ){
                    let params = {
                        coins_pago : this.coinsPago,
                        id_premio : this.id_premio,
                        tipo_pago : this.coinSelect,
                        id_cedis : this.premioDetalle.id_cedis,
                        id_direccion : this.id_direccion
                    };
                    const { data } = await Premios.solicitarCanjePremio( params );
                    this.idCanje = data.numCanje;
                    this.$router.push({ name: 'premios.canjear', params: { id_canje: this.idCanje }})   
                }else{
                    this.notificacion('','Error al realizar el pedido','warning');
                }
            } catch (e){
                this.errorCatch(e)
            }   
        }
    }
}
</script>
<style lang="scss" scoped>
.select-coin{
    width: 125px;
    height: 36px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFE4E8;
    border-radius: 8px;
    &-active{
        @extend .select-coin;
        border: 2px solid var(--color-general);
    }
}
.cobertura-alert{
    height: 30px;
    max-width: 350px;
    border: 1px solid #FF5806;
    color: #FF5806;
    border-radius: 15px;
}

@media (min-width: 300px) and (max-width: 985px){
    section{
        height: calc(100vh - 150px) !important;
    }
}
</style>