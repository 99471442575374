<template>
	<section class="px-xl-5 px-md-4 px-sm-2 px-2 j-center custom-scroll overflow-auto" style="height: calc(100vh - 75px)">
		<cargando v-if="cargando" />
		<div id="sign-in-button" />
		<div class="row mx-0 title-category mb-4 mt-4">
			<p class="col px-0 f-28 text-general f-600">Perfil</p>
			<div class="col-12" />
			<div class="like-bar mb-2" />
		</div>
		<div
			class=" row mx-0 br-12 bg-white p-3 shadow-14 justify-content-md-center justify-content-sm-center justify-content-center">
			<div class="text-center" style="width: 160px">
				<img :src="datoCliente.imagen == null ? '/img/no-imagenes/sin_cliente.svg' : datoCliente.imagen"
					class="obj-cover cr-pointer border rounded-circle" width="142" height="142"
					@click="seleccionarAvatar" />
				<p class="f-14 text-center mt-2">Seleccione el avatar</p>
			</div>
			<ValidationObserver ref="form">
				<div
					class=" col-xl col-lg col-md-12 col-sm-12 col-12 pl-xl-5 pl-lg-4 pl-md-0 pl-sm-0 pl-0 mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4">
					<div class="row mx-0">
						<div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
							<ValidationProvider v-slot="{ errors }" rules="required|max:17" name="nombre">
								<label class="text-general pl-3 f-12">Nombres</label>
								<el-input v-model="datoCliente.nombre" placeholder="Nombres" class="w-100"
									:maxlength="17" />
								<span class="text-danger f-11"> {{ errors[0] }} </span>
							</ValidationProvider>
						</div>
						<div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
							<ValidationProvider v-slot="{ errors }" rules="required|max:18" name="apellido">
								<label class="text-general pl-3 f-12">Apellidos</label>
								<el-input v-model="datoCliente.apellido" placeholder="Apellidos" class="w-100"
									:maxlength="18" />
								<span class="text-danger f-11"> {{ errors[0] }} </span>
							</ValidationProvider>
						</div>
					</div>
					<div class="row mx-0 mt-4">
						<div
							:class="datoCliente.tipoIdentificacion ? 'col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12' : 'col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12'">
							<label class="text-general pl-3 f-12">Fecha de nacimiento</label>
							<el-date-picker v-model="datoCliente.nacimiento" type="date" placeholder="Seleccionar"
								class="w-100" value-format="yyyy-MM-dd" />
						</div>
						<div v-if="datoCliente.tipoIdentificacion" class="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
							<label class="text-general pl-3 f-12">{{ datoCliente.tipoIdentificacion }}</label>
							<el-input v-model="datoCliente.identificacion" :placeholder="datoCliente.tipoIdentificacion"
								class="w-100" />
						</div>
						<div
							:class="datoCliente.tipoIdentificacion ? 'col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12' : 'col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12'">
							<label class="text-general pl-3 mb-3 f-12 w-100">Genero</label>
							<div class="d-flex pl-3">
								<el-radio v-model="datoCliente.genero" :label="3" class="radio-green">
									Mujer
								</el-radio>
								<el-radio v-model="datoCliente.genero" :label="2" class="radio-green">
									Hombre
								</el-radio>
								<el-radio v-model="datoCliente.genero" :label="1" class="radio-green">
									Otro
								</el-radio>
							</div>
						</div>
					</div>
					<div class="row mx-0 mt-4" />
					<div class="row mx-0 mt-4">
						<div class="col-auto">
							<div class="btn-general px-4" @click="editCliente()">
								Actualizar datos
							</div>
						</div>
					</div>
				</div>
			</ValidationObserver>
		</div>
		<div class="row mx-0 title-category mt-5 mb-4">
			<p class="col px-0 f-28 text-general f-600">Datos de acceso</p>
			<div class="col-12" />
			<div class="like-bar mb-2" />
		</div>
		<div class="row mx-0">
			<div class=" col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12 pl-0 pr-xl-4 pr-md-3 pr-sm-0 pr-0">
				<div :class="`card-access${accesoNum ? '-verified' : ''
					} cr-pointer border px-2`" style="height: 44px" @click="asignarTelefono()">
					<i class="icon-cellphone f-17" />
					<img :src="datoCliente.bandera" class="obj-cover br-2" width="22" height="15" />
					<div class="col">
						+ {{ datoCliente.indicativo }} {{ datoCliente.telefono }}
					</div>
					<i v-if="accesoNum" class="icon-ok-circled-outline text-green f-18" />
				</div>
				<div :class="`card-access${accesoGoogle ? '-verified' : ''
					} cr-pointer border px-2 pl-2 mt-3`" style="height: 44px" @click="onRegisterWithGoogle">
					<i class="icon-lock f-18" />
					<div v-if="accesoGoogle" class="col tres-puntos lh-16 my-auto">
						Vinculado a Google
						<div v-if="accesoMail && accesoGoogle">
							<p class="f-11">{{ datoCliente.correo }}</p>
						</div>
					</div>
					<div v-else class="col tres-puntos">
						Vincular tu cuenta de Google
					</div>
					<i v-if="accesoGoogle" class="icon-ok-circled-outline text-green f-18" />
				</div>
				<div :class="`card-access${accesoPass ? '-verified' : ''
					} cr-pointer border px-2 pl-2 mt-3`" style="height: 44px">
					<i class="icon-lock f-18" />
					<div v-if="accesoPass" class="col" @click="asignarContrasena">
						Contraseña asociada
					</div>
					<div v-else class="col" @click="asignarContrasena">
						Asignar contraseña
					</div>
					<i v-if="accesoPass" class="icon-ok-circled-outline text-green f-18" />
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12 pl-0 pr-sm-0 pr-0">
				<!-- <div :class="`card-access${accesoMail ? '-verified' : ''
					} cr-pointer border px-2 mt-xl-0 mt-md-0 mt-sm-3 mt-3`" style="height: 44px">
					<i class="icon-mail f-17" />
					<div v-if="accesoMail" class="col">
						{{ datoCliente.correo }}
					</div>
					<div v-else class="col">
						Vincular tu correo electrónico
					</div>
					<i v-if="accesoMail" class="icon-ok-circled-outline text-green f-18" />
				</div> -->
				<!-- <div :class="`card-access${accesoFace ? '-verified' : ''
					} cr-pointer border px-2 pl-2 mt-3`" style="height: 44px" @click="accesoFace = !accesoFace">
					<img src="/img/icons/facebook.svg" width="24" height="24" />
					<div v-if="accesoFace" class="col tres-puntos">
						Vinculado a Facebook
					</div>
					<div v-else class="col tres-puntos">
						Vincular tu cuenta de Facebook
					</div>
					<i v-if="accesoFace" class="icon-ok-circled-outline text-green f-18" />
				</div> -->
			</div>
		</div>
		<!-- Partials -->
		<modal-selec-avatar ref="modalSelecAvatar" @update="datosCliente" />
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '~/services/cliente/cliente'
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import firebase from '~/library/firebase';
export default {
	components: {
		modalSelecAvatar: () => import("./partials/modalSelecAvatar"),
	},
	data() {
		return {
			cargando: false,
			nombres: "",
			apellidos: "",
			accesoNum: false,
			accesoGoogle: false,
			accesoPass: false,
			accesoMail: false,
			accesoFace: false,
			nacimiento: null,
			datoCliente: {},
		};
	},
	computed: {
		...mapGetters({
			usuario: 'auth/obtenerUsuario',
		}),
	},
	mounted() {
		this.datosCliente();
	},
	methods: {
		asignarContrasena() {
			this.$router.push({ name: 'asignar-pass' })
		},
		asignarTelefono() {
			this.$router.push({ name: 'asignar-phone' })
		},
		seleccionarAvatar() {
			this.$refs.modalSelecAvatar.toggle();
		},
		async datosCliente() {
			try {
				this.cargando = true;

				const { data } = await Service.getDatosCliente();
				this.datoCliente = data.datosCliente;

				if (this.datoCliente.contrasena != null) {
					this.accesoPass = true
				};
				if (this.datoCliente.google != null) {
					this.accesoGoogle = true
				};
				if (this.datoCliente.facebook != null) {
					this.accesoFace = true
				};
				if (this.datoCliente.correo != null) {
					this.accesoMail = true
				};
				if (this.datoCliente.telefono != null) {
					this.accesoNum = true
				};
			} catch (e) {
				this.errorCatch(e)
			} finally {
				this.cargando = false;
			}
		},
		async onRegisterWithGoogle() {
			try {
				const proveedor = new GoogleAuthProvider();
				proveedor.addScope('email');
				this.abrirPopoverConexion(proveedor);
			} catch (error) {
				this.errorCatch(error)
			}
		},
		async abrirPopoverConexion(proveedor) {
			const auth = getAuth();
			await signInWithPopup(auth, proveedor)
				.then((result) => {
					/*
					- result: Retornara la información que tiene google del usuario, como correo, nombre etc...
					- credential: Retornara la información de acceso a google, como el token de acceso etc...
					*/
					const credential = GoogleAuthProvider.credentialFromResult(result);
					const payload = {
						provider: 'google',
						token: credential.accessToken,
						tipo: 3
					};

					this.vincularGoogle(payload)
						.catch(this.errorCatch)
						// .finally(() => {
						// });

				}).catch((error) => {
					// error: Retornara información acerca de los errores enviado por google
					this.notificacion('No es posible', 'Ocurrio un error al intentar conectar con google', 'error');
				});
		},

		async vincularGoogle(payload) {
			try {
				const { data } = await Service.vincularSocial(payload)
				this.datoCliente.correo = data.data
				this.accesoGoogle = true;
				this.accesoMail = true;
				this.notificacion('', 'Cuenta de google vinculada correctamente', 'success')
				return data;
			} catch (error) {
				this.errorCatch(error)
			}
		},

		async editCliente() {
			try {
				const valid = await this.$refs.form.validate()
				if (!valid) return;

				const payload = {
					nombre: this.datoCliente.nombre,
					apellidos: this.datoCliente.apellido,
					nacimiento: this.datoCliente.nacimiento,
					genero: this.datoCliente.genero,
					identificacion: this.datoCliente.identificacion
				};
				const { data } = await Service.editCliente(payload);
				this.$store.dispatch('auth/consultarUsuario')
				this.notificacion('Datos actualizados', data.mensaje, 'success');
			} catch (e) {
				this.errorCatch(e);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.box {
	height: calc(100% - 3px) !important;
	width: 805px;
	box-shadow: 0px 1px 4px #00000014 !important;
	border-radius: 6px 6px 0px 0px;

	.title-box {
		font-size: 30px;
		color: #000000;
		font-weight: 500;
	}
}

.card-access {
	height: 44px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	border-radius: 12px;
	border: 1px solid #dfe4e8;

	&-verified {
		@extend .card-access;
		border: 1px solid #29d884;
	}
}

@media (min-width: 300px) and (max-width: 985px) {
	section {
		padding-top: inherit !important;
		height: calc(100vh - 96px) !important;
	}

	.box {
		width: 100% !important;
		height: calc(100% - 1px) !important;
	}
}
</style>