<template>
    <section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 75px)">
        <div class="box d-flex flex-column">
            <p class="title text-center pt-5">
                Nueva <br /> contraseña
            </p>
            <div class="row mx-0 j-center mt-4">
                <div class="col-9 mb-4">
                    <small class="pl-3 text-general">
                        Nueva Contraseña
                    </small>
                    <el-input v-model="nueva_pass" placeholder="Nueva contraseña" show-password class="w-100" />
                </div>
                <div class="col-9 mb-4">
                    <small class="pl-3 text-general">
                        Confirmar Contraseña
                    </small>
                    <el-input v-model="rep_pass" placeholder="Repetir contraseña" show-password class="w-100" />
                </div>
                <div class="col-9">
                    <div class="btn-general f-18 br-8" style="height:44px;" @click="asignarPassword()">
                        Guardar 
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-error-pass ref="modalErrorPass" />
    </section>
</template>

<script>
import Service from '~/services/cliente/cliente'
export default {
    components: {
        modalErrorPass: () => import('./partials/modalErrorPass')
    },
    data(){
        return {
            nueva_pass: '',
            rep_pass: '',
        }
    },
    methods: {
        errorPass(){
            this.$refs.modalErrorPass.toggle();
        },
        async asignarPassword(){
            try {
                if( this.nueva_pass == this.rep_pass){
                    const payload = {
                        contrasena: this.nueva_pass,
                        contrasena_confirmation: this.rep_pass,
                    };
                    await Service.newPassword(payload);
                    this.$router.push({ name:'mi-perfil' })}
                else{
                    this.errorPass();
                }
            } catch (error){
                console.error(error);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.box{
    width: 597px;
    height: 55vh;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
    .title{
        font-size: 37px !important;
        font-weight: 600;
    }
}

@media (min-height: 300px) and (max-height: 780px) {
    .box{
        height: 69vh !important;
    }
}
@media (min-width: 300px) and (max-width: 695px) {
    .box{
        height: calc(100% - 1px) !important;
        width: 100%;
        border-radius: inherit !important;
    }
}
</style>