<template>
    <section class="d-middle-center overflow-auto custom-scroll py-4">
        <div class="col-auto">
            <div class="row mx-0 j-center">
                <img :src="funImagenGeneral(26)" class="img-canje" width="380" height="380" />
            </div>
            <p class="text-center f-20 f-600">
                Canje No {{ idCanje }}
            </p>
            <p class="text-center f-15 f-300">
                Creado exitosamente
            </p>
            <p class="text-center f-15 mt-3">
                Te estaremos informando acerca de <br /> la entrega
            </p>
            <div class="btn-general mx-5 mt-3" @click="$router.push({name: 'home.tienda'})">
                Ir al inicio
            </div>
        </div>
    </section>
</template>

<script>
export default {
    middleware:'menu-check',
    data(){
        return {
            idCanje: this.$route.params.id_canje,
        }
    }
}
</script>
<style lang="scss" scoped>
section{
    height: 100vh;
}

@media (min-width: 300px) and (max-width: 1024px){
    .img-canje{
        width: 280px;
        height: 280px;
    }
}
</style>