<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 76px)">
        <div class="pb-5" style="min-height:100vh;">
            <div class="row mx-0 j-center">
                <div class="spacer" />
            </div>
            <div class="row mx-0 j-center px-3 px-lg-0">
                <div class="col-12 col-lg-8 mb-5">
                    <p class="text-general f-300 mb-2 f-14">
                        <i v-if="route" class="icon-back cr-pointer" @click="goBack" />
                        {{ $config.proyecto }}
                    </p>
                    <p class="text-general f-28 f-500">
                        Términos y condiciones del uso de la plataforma de {{ $config.proyecto }}
                    </p>
                    <div class="like-bar mt-2" />
                </div>
                <div v-for="(t,index) in data" :key="index" class="col-12 col-lg-8 my-4">
                    <p class="text-general f-17 f-600">
                        {{ t.titulo }}
                    </p>
                    <p class="text-general2 f-15 text-answer" style="">
                        {{ t.texto }}
                    </p>
                </div>
            </div>
        </div>
        <!-- <footer-landing /> -->
    </section>
</template>

<script>

import Service from '@/services/faqs/faqs'
export default {
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.route = from.name;
        });
    },
    data(){
        return {
            route: null,
            data: []
        }
    },
    mounted(){
        this.getData()
    },
    methods: {
        async getData(){
            try {
                const { data } = await Service.getTerminosCodiciones()
                this.data = data.data
            } catch (error){
                this.errorCatch(error)
            }
        },

        goBack(){
            if(!this.route)return;
            this.$router.push({ name: this.route });
        },
    }
}
</script>
<style lang="scss" scoped>
.text-answer{
    white-space: pre-line ;
}
.spacer{
    height: 50px !important;
}
</style>