import axios from 'axios'

const api = "faqs"

const Faqs = {
    getTerminosCodiciones(){
        return axios.get(`listar/terminos-condiciones`)
    },
    getPoliticasPrivacidad(){
        return axios.get(`listar/politicas-privacidad`)
    },
    listarFaqs: (params) => axios.get(`${api}/get`,{params}),
    getFaqsUsuario: (params) => axios(`${api}/faqs-usuario`,{params}),
    
}

export default Faqs