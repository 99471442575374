<template>
    <section class="px-xl-5 px-lg-3 px-md-3 px-sm-3 px-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 84px)">
        <div class="row mx-0 mb-4">
            <i class="icon-back cr-pointer f-22 mt-1" @click="$router.back()" />
            <div class="col">
                <div class="row mx-0 a-center mb-4">
                    <div class="col-auto">
                        <p class="px-0 f-28 text-general f-600">
                            Mis canjes de premios
                        </p>
                        <div class="w-100" />
                        <div class="like-bar mb-2" />
                    </div>
                    <div v-if=" detalleProducto.estado != 21 && detalleProducto.estado != 4" class="btn-outline ml-auto px-4" @click="modalEliminarCanje">
                        Cancelar canje
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-xl col-lg col-md col-sm-12 col-12 mr-3 bg-white br-10 py-3 px-3 shadow-14">
                        <div class="section-state mx-5">
                            <div v-if="historialEstados[3] != 21" class="row mx-0 j-center my-3">
                                <div v-for="(act, index) in historialEstados" :key="index" class="px-0 d-middle" style="width:49px;">
                                    <div v-if="act.estado" class="rounded-circle  position-relative " :class="act.estado?'border-purple':'bg-gris'" style="width:40px;height:40px;padding:1px">
                                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                            <i :class="`f-20 icon-${iconosEstados(index)} ${act.estado?'text-purple':'text-gris'}`" />
                                        </div>
                                    </div>
                                    <div v-if="index != historialEstados.length - 1 " class="w-100 border-purple" style="height:2px" />
                                </div>
                            </div>
                            <div v-else class="row mx-0 j-center my-3">
                                <div v-for="(act, index) in historialEstados.slice(0, 3)" :key="index" class="px-0 d-middle" style="width:49px;">
                                    <div class="rounded-circle position-relative" :class="act.estado?'border-purple':'bg-gris'" style="width:40px;height:40px;padding:1px">
                                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                            <i :class="`f-20 icon-${iconosEstados(index)} ${act.estado?'text-purple':'text-gris'}`" />
                                        </div>
                                    </div>
                                    <div v-show="index != historialEstados.length-2" class="w-20" :class="act.estado?'border-purple':'bg-gris'" style="height:2px" />
                                </div>
                            </div>
                            <p v-if="detalleProducto.estado == 1" class="text-center text-general f-16 f-500 mb-3">
                                Esperando confirmación del canje
                            </p>
                            <p v-else-if="detalleProducto.estado == 2" class="text-center text-general f-16 f-500 mb-3">
                                El pedido será entregado
                            </p>
                            <p v-else-if="detalleProducto.estado == 4" class="text-center text-general f-16 f-500 mb-3">
                                El pedido ha sido entregado
                            </p>
                            <p v-else-if="detalleProducto.estado == 21" class="text-center text-general f-16 f-500 mb-3">
                                El pedido ha sido cancelado
                            </p>
                            <template v-if="detailHistory">
                                <div v-for="(act, index) in historialEstados" :key="index" class="row mx-4 mb-1">
                                    <div v-if="act.estado" class="col-auto">
                                        <div class="rounded-circle position-relative" :class="act.estado?'bg-black':'bg-gris'" style="width:18px;height:18px;padding:1px">
                                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                                <i v-if="index == 3" :class="`f-20 ${act.estado ? 'icon-cancel-circled-outline' : ''}  text-general`" />
                                                <i v-else :class="`f-20 ${act.estado ? 'icon-ok-circled-outline' : ''}  text-general`" />
                                            </div>
                                        </div>
                                        <div v-if="index != historialEstados.length - lineaEstado(detalleProducto.estado)" class="mx-auto h-100 py-2" :class="act.estado?'bg-black':'bg-gris'" style="width:2px;" />
                                    </div>
                                    <div v-if="act.estado" class="col">
                                        <div class="row mx-0">
                                            <div class="col px-1">
                                                <p class="f-14" :class="act.estado?'text-general':'text-gris2'"> {{ textoCanje(index) }} </p>
                                                <p v-if="act.estado" class="f-13 text-gris2">{{ formatearFecha(act.created_at, formatoDestino = 'hh:mma' ) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row mx-0 j-center mt-2 mb-3">
                                <div class="col-auto cr-pointer px-2 f-15" @click="detailHistory = !detailHistory">
                                    Ver más <i :class="`icon-${!detailHistory ? 'down-open' : 'up-open'} ml-2`" />
                                </div>
                            </div>
                        </div>
                        <div class="card-canje cr-pointer p-3 mx-4 d-flex bg-white mb-3">
                            <img :src="detalleProducto.imagen" width="90" height="90" />
                            <div class="col px-3">
                                <p class="f-12 pt-3">
                                    {{ detalleProducto.nombre }}
                                </p>
                                <div class="row mx-0 a-center my-1">
                                    <img v-if="detalleProducto.monedas != 0" src="/img/ilustraciones/gaming/i_moneda.svg" width="25" height="25" />
                                    <img v-else src="/img/ilustraciones/gaming/i_gema.svg" width="25" height="25" />
                                    <div v-if="detalleProducto.monedas != 0" class="col-auto px-2 f-600">
                                        {{ detalleProducto.monedas }}
                                    </div>
                                    <div v-else class="col-auto px-2 f-600">
                                        {{ detalleProducto.gemas }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section-state mx-4 mb-3">
                            <div class="row mx-0">
                                <i class="icon-map-pin f-20" />
                                <div class="col px-2 f-17 f-600">
                                    Dirección de entrega
                                    <p class="f-15 mt-3 f-400">
                                        {{ detalleProducto.direccion }}
                                    </p>
                                    <p v-if="detalleProducto.barrio != null" class="f-15 mt-3 f-400">
                                        "Barrio": {{ detalleProducto.barrio }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="section-state mx-4">
                            <div class="row mx-0">
                                <i class="icon-card-text-outline f-20" />
                                <div class="col px-2 f-17 f-600">
                                    Más información 
                                    <div class="row mx-0">
                                        <p class="col-auto px-0 f-400 f-15">
                                            No. del canje
                                        </p>
                                        <p class="col-auto px-0 f-600 f-15 ml-auto text-right">
                                            {{ detalleProducto.id }}
                                        </p>
                                    </div>
                                    <div class="row mx-0 mt-1">
                                        <p class="col-auto px-0 f-400 f-15">
                                            Fecha de solicitud
                                        </p>
                                        <p class="col-auto px-0 f-15 f-600 ml-auto text-right">
                                            {{ formatearFecha(detalleProducto.created_at) }} <br />
                                            {{ formatearFecha(detalleProducto.created_at, formatoDestino = 'hh:mma' ) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl col-lg col-md col-sm-12 col-12 px-0 ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0 ml-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-3 bg-white br-10 py-2 shadow-14">
                        <div class="row mx-0 border-bottom py-2">
                            <div class="col-12">
                                <el-input v-model="comentario" placeholder="Comentario" class="w-100 br-20">
                                    <i v-if="comentario.length > 0" slot="suffix" class="el-input__icon f-20 icon-send-message text-general text-purple" @click="postComentario" />
                                </el-input>
                            </div>
                        </div>
                        <div v-if="comentarios.length == 0" class="custom-scroll overflow-auto mt-5" style="height:calc(100vh - 291px)">
                            <div class="col-auto px-xl-4 px-lg-4 px-md-4 px-sm-0 px-0 text-center">
                                <img :src="funImagenGeneral(27)" width="250" style="max-height:100%;" />
                                <p class="text-center f-17 mt-4">
                                    Aún no se han hecho comentarios.
                                </p>
                            </div>
                        </div>
                        <div v-else class="custom-scroll overflow-auto" style="height:calc(100vh - 291px)">
                            <div v-for="data in comentarios" :key="data.id" class="row mx-0 border-bottom py-3 px-4">
                                <img v-if="data.tipo == 3 || data.tipo == 1" src="/img/bell.svg" class="obj-cover rounded-circle" width="35" height="35" />
                                <img v-else :src="usuario.avatar" class="obj-cover rounded-circle" width="35" height="35" />
                                <div class="col px-2">
                                    <p v-if="data.tipo == 3 || data.tipo == 1" class="f-600 f-16">
                                        {{ $config.proyecto }}
                                    </p>
                                    <p v-else class="f-600 f-16">
                                        {{ usuario.nombre }}
                                    </p>
                                    <p class="text-general f-14 mt-1">
                                        {{ data.comentario }}
                                    </p>
                                    <p class="text-gris2 f-500 f-16 mt-3">
                                        {{ formatearFecha(data.created_at, formatoDestino = 'D MMM Y hh:mma' ) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-confirmar ref="modalEliminarPedido" titulo="Cancelar Canje" mensaje="¿Desea cancelar el canje?" @adicional="cancelarCanje" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import CanjePremios from '~/services/gaming/gamingCanjes'
export default {
    middleware:'menu-check',
    data(){
        return {
            comentarios: [],
            detailHistory: false,
            id_canje: this.$route.params.id_canje,
            detalleProducto: {},
            historialEstados: [],
            comentario: '',
        }
    },
    computed:{
        ...mapGetters({
            usuario: 'auth/obtenerUsuario',
        })
    },
    mounted(){
        this.getDetallePremio();
    },
    methods: {
        modalEliminarCanje(){
            this.$refs.modalEliminarPedido.toggle();
        },
        async getDetallePremio(){
            try {
                const { data } = await CanjePremios.getPremioDetalle(this.id_canje);
                this.detalleProducto = data.detalleCanje;
                this.historialEstados = data.historialEstados;
                this.comentarios = data.comentarios.original.comentarios;
            } catch (e){
                this.errorCatch(e)
            }    
        },
        async cancelarCanje(){
            try {
                const { data } = await CanjePremios.cancelarPedido(this.id_canje);
                this.$router.back()
                this.notificacion('Exito','Pedido cancelado con éxito','success');
            } catch (e){
                this.errorCatch(e)
            }    
        },
        async postComentario(){
            try {
                let payload= {
                    id_canje : this.id_canje,
                    comentario : this.comentario
                }
                const { data } = await CanjePremios.nuevoComentario(payload)
                this.comentario = '';
                this.getComentariosCanje(); 
            } catch (e){
                this.errorCatch(e)
            }    
        },
        async getComentariosCanje(){
            try {
                const { data } = await CanjePremios.getComentariosLista(this.id_canje);
                this.comentarios = data.comentarios;
            } catch (e){
                this.errorCatch(e)
            }    
        },
        iconosEstados(state){
            switch (state){
            case 0:
                return 'order';
            case 1:
                return 'ok-circled-outline';
            case 2:
                return 'order-packed';
            case 3:
                return 'cancel-circled-outline';
            }
        },
        textoCanje(state){
            switch (state){
            case 0:
                return 'Has solicitado un pedido';
            case 1:
                return 'El pedido ha sido confirmado';
            case 2:
                return 'El pedido ha sido entregado';
            case 3:
                return 'El pedido ha sido cancelado';
            }
        },
        lineaEstado(state){
            switch (state){
            case 1:
                return '4';
            case 2:
                return '3';
            case 4:
                return '2';
            case 21:
                return '1';
            }
        },


    }
}
</script>
<style lang="scss" scoped>
.card-canje{
    height: 114px;
    border-radius: 8px;
    border: 1px solid #DFE4E8;

    &:hover{
        box-shadow: 0px 3px 6px #0000000D;
    }
}
</style>

