<template>
    <section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 76px)">
        <div class="box px-4 overflow-auto custom-scroll">
            <div class="row mx-0 a-center mb-5">
                <div class="col-auto">
                    <p class="f-30 text-general f-500">
                        Hola Andrew, ¿En qué te <br /> podemos ayudar?
                    </p>
                    <div class="like-bar mt-2" />
                </div>
            </div>
            <div class="row mx-0 mb-5">
                <div class="col-12">
                    <p class="f-12 text-general pl-3">Cuéntanos</p>
                    <el-input v-model="cuentanos" placeholder="¿Que problema has tenido?" class="w-100" type="textarea" :rows="5" size="small" />
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-12 pl-3">
                        Adjunta tus evidencias
                    </p>
                </div>
                <div class="col-12 mt-2 px-4">
                    <div class="row mx-0">
                        <el-upload
                        action="https://jsonplaceholder.typicode.com/posts/"
                        class="upload-soporte"
                        list-type="picture-card"
                        >
                            <!-- <i class="el-icon-plus" /> -->
                            <img src="/img/no-imagenes/añadir_imagen.svg" width="100%" height="99%" />
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col-12 d-flex px-4">
                    <div class="btn-general px-5 br-8" style="height:44px;">
                        Enviar
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            soportes: [
                {
                    id: 1547812,
                    fecha: '15 Julio 2020',
                    abierto: true
                },
                {
                    id: 2347812,
                    fecha: '15 Julio 2020',
                    abierto: false
                },
                {
                    id: 3547812,
                    fecha: '15 Julio 2020',
                    abierto: false
                }
            ],
            cuentanos: ''
        }
    }
}
</script>
<style lang="scss" scoped>
.box{
    width: 671px;
    height: 84vh;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
    .status{
        width: 20px;
        height: 20px;
        background-color: #DBDBDB;
        border-radius: 50%;
        &.active{ background-color: var(--color-general) !important; }
    }
}
</style>