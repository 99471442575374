<template>
    <section class="pt-4 px-4 overflow-auto custom-scroll scroll-none" style="height:calc(100vh - 76px)">
        <div class="row mx-0">
            <i class="icon-back f-22 mt-1" @click="$router.back()" />
            <div class="col-xl-5 col-lg-7 col-md-10 col-sm-10 col-12 px-3">
                <div class="row mx-0 mb-5 a-center">
                    <div class="col-auto px-0">
                        <p class="f-30 text-general f-600">
                            Crédito
                        </p>
                        <div class="like-bar mt-2" />
                    </div>
                    <div :class="`mn-pill ${chats ? 'bg-orange text-white' : 'bg-white text-gris2'}  px-2 ml-auto`" style="height:32px;">
                        <i class="icon-chat f-16" />
                    </div>
                </div>
                <div v-if=" pedido.pago_estado != 4 && pedido.pago_estado != 3" class="bg-white border br-8 p-3 mb-3">
                    <p class="text-general text-center f-17 f-600 mb-3">
                        Estado de la deuda
                    </p>
                    <div class="row mx-0 mb-2 br-20 bg-fondo2" style="height:10px;">
                        <div class="h-100 br-20 bg-general" :style="`width:${porcentajePago}%;`" />
                    </div>
                    <div class="row mx-0 px-2 mt-3">
                        <div class="col-auto px-0">
                            <span class="f-14 text-general">Pago:</span>
                            <p class="text-purple f-600 f-20"> {{ formatNumero(pedido.total_pagado) }}</p>
                        </div>
                        <div class="col-7" />
                        <div class="col-auto px-0">
                            <span class="f-14 text-general">Deuda:</span>
                            <p class="text-red f-600 f-20"> {{ formatNumero(saldoDeuda) }} </p>
                        </div>
                    </div>
                </div>
                <div v-if="pedido.pago_estado == 4" class="info-credit-anuled">
                    <i class="icon-credit-cancel f-18" />
                    <span class="pl-2">Crédito anulado</span>
                </div>
                <div v-if="pedido.pago_estado == 3" class="info-credit-payment mt-2 mb-4">
                    <i class="icon-ok-circled-outline f-18" />
                    <span class="pl-2">Crédito pagado</span>
                </div>
                <div class="section-information">
                    <div class="row mx-0 mb-3">
                        <i class="icon-order f-18" />
                        <div class="col px-2 text-general f-17 f-600">
                            Información del pedido
                        </div>
                    </div>
                    <div class="row mx-0 a-center mb-2">
                        <div class="col-auto px-0 f-15">
                            Valor
                        </div>
                        <div class="col-auto px-0 f-24 f-600 ml-auto">
                            {{ formatNumero(pedido.total_credito) }}
                        </div>
                    </div>
                    <div class="row mx-0 a-center mb-2">
                        <div class="col-auto px-0 f-15">
                            No. del pedido
                        </div>
                        <div class="col-auto px-0 f-15 f-600 ml-auto">
                            {{ pedido.id }}
                        </div>
                    </div>
                    <div class="row mx-0 a-center mb-2">
                        <div class="col-auto px-0 f-15">
                            Fecha de pedido
                        </div>
                        <div class="col-auto text-right px-0 f-15 f-600 ml-auto">
                            {{ formatearFecha(pedido.entrega_fecha ) }} <br /> 
                        </div>
                    </div>
                    <div class="row mx-0 a-center mb-2">
                        <div class="col-auto px-0 f-15">
                            Productos
                        </div>
                        <div class="col-auto text-right px-0 f-15 f-600 ml-auto">
                            {{ pedido.cant_productos }}
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <img :src="pedido.tienda_logo" class="obj-cover br-4" width="46" height="46" />
                        <div class="col-auto">
                            <p class="text-general f-600 f-17">{{ pedido.tienda_nombre }}</p>
                            <div class="row mx-0 a-center">
                                <i class="icon-client-cap" />
                                <span class="f-15 ml-2">{{ $config.vendedor }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btn-outline mt-3 mb-4">
                        Ir al pedido
                    </div>
                    <div class="section-cuota mb-5">
                        <div class="row mx-0 a-center pr-1 mb-3 bg-fondo2 br-20 text-general" style="height:35px;">
                            <p class="col-auto f-15 f-400">
                                Cuotas establecidas
                            </p>
                            <div class="bg-black ml-auto rounded-circle text-white f-14 text-center" style="width:20px;height:20px;">
                                {{ cantCuotas }}
                            </div>
                        </div>
                        <div v-for="(c, idx) in cuotas" :key="idx" class="px-0 py-2 border br-8 mb-3 bg-white">
                            <div class="row mx-0">
                                <p class="col-6 text-general f-16 f-600">{{ formatNumero(c.valor) }}</p>
                                <p class="col-6 pl-0 text-right text-general f-15">
                                    <span v-text="`${textoCuotas(c.estado)}`" />
                                    <i :class="`${iconosCuotas(c.estado)}`" />
                                </p>
                                <div class="col-6 text-general f-15">
                                    {{ formatearFecha(c.fecha, formatoDestino='DD MMMM YYYY') }}
                                </div>
                                <div v-if="c.estado == 2" class="col-6 text-right text-general f-15">
                                    {{ formatNumero( c.valor_pendiente ) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-history">
                        <div class="row mx-0 a-center pr-1 mb-3 bg-fondo2 br-20 text-general" style="height:35px;">
                            <p class="col-auto f-15 f-400">
                                Historial de pagos
                            </p>
                            <div class="bg-black ml-auto rounded-circle text-white f-14 text-center" style="width:20px;height:20px;">
                                {{ cantPagos }}
                            </div>
                        </div>
                        <div v-for="(historial, h) in pagos" :key="h" class="bg-white px-0 py-2 border br-8 mb-3 cr-pointer">
                            <div class="row mx-0" @click="mirarDetallePago( historial )">
                                <div class="col-auto pr-1">
                                    <img v-if="historial.tipo == 1" :src="`/img/ilustraciones/cash.svg`" />
                                    <img v-else-if="historial.tipo == null || 2 || 9" :src="`/img/icons/pago_online.svg`" />
                                </div>
                                <div class="col px-0 pt-1">
                                    <div class="row mx-0">
                                        <p class="col text-general tres-puntos f-16 f-500">
                                            {{ formatNumero(historial.valor) }}
                                        </p>
                                        <div class="col d-flex justify-content-end">
                                            <div :class="`${estadoPago(historial.estado)} px-3 br-20`">
                                                {{ textoPago(historial.estado) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mx-0 my-2">
                                        <p v-if="historial.tipo == null" class="col text-general">
                                            En linea
                                        </p>
                                        <p v-else-if="historial.tipo == 1" class="col text-general">
                                            Efectivo
                                        </p>
                                        <p v-else-if="historial.tipo == 2" class="col text-general">
                                            Transferencia
                                        </p>
                                    </div>
                                    <div class="row mx-0">
                                        <p class="col-12 f-15">
                                            {{ formatearFecha(historial.created_at, formatoDestino='DD MMM YYYY - hh:mm a') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-detalle-pago ref="modalDetallePago" />
    </section>
</template>

<script>
import Creditos from '~/services/creditos/creditos'
export default {
    middleware:'menu-check',
    components: {
        modalDetallePago: () => import('./partials/modalDetallePago')
    },
    data(){
        return{
            id_pedido: this.$route.params.id_credito,
            chats: false,
            cuotas: [],
            pagos: [],
            pedido: {},
            cantCuotas: null,
            cantPagos: null,
            porcentajePago: null,
            saldoDeuda: null
        }
    },
    mounted(){
        this.getInfoCredito();
    },
    methods: {
        mirarDetallePago(item){
            this.$refs.modalDetallePago.toggle(item, this.pedido);
        },
        async getInfoCredito(){
            try {
                const { data } = await Creditos.verCreditosDetalle(this.id_pedido);
                this.cuotas = data.cuotas;
                this.cantCuotas = this.cuotas.length;
                this.pagos = data.pagos;
                this.cantPagos = this.pagos.length;
                this.pedido = data.pedido;
                this.saldoDeuda = (data.pedido.total_credito - data.pedido.total_pagado)
                this.porcentajePago = (data.pedido.total_pagado / data.pedido.total_credito * 100) 
            } catch (e){
                this.errorCatch(e)
            }          
        },
        estadoPago(state){
            switch (state){
            case 0:
            case 2:
                return 'bg-aprobado';
            case 9:
            case 1:
                return 'bg-pendiente';
            case 3:
                return 'bg-rechazado';
            }
        },
        textoPago(state){
            switch (state){
            case 0:
            case 2:
                return 'Aprobado';
            case 9:
            case 1:
                return 'Pendiente';
            case 3:
                return 'Rechazado';
            }
        },
        iconosCuotas(state){
            switch (state){
            case 1:
                return 'icon-ok-circled-outline text-green f-18';
            case 2:
                return 'icon-alert-triangle text-orange f-14';
            case 3:
                return 'icon-alert-circle-outline text-red f-18';
            case 4:
                return 'icon-alert-circle-outline text-general f-18';
            }
        },
        textoCuotas(state){
            switch (state){
            case 1:
                return 'Pagada';
            case 2:
                return 'Pago parcial';
            case 3:
                return 'Cuota vencida';
            case 4:
                return 'Próxima cuota';
            }
        },
        registrarPago(){
            this.$refs.modalValorPagar.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: underline;
}

.info-credit{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 8px;
    &-anuled{
        @extend .info-credit;
        border: 1px solid #FF1720;
        background-color: #FF17201A;
        color: #FF1720;
        font-size: 17px;
        font-weight: 600;
    }
    &-payment{
        @extend .info-credit;
        border: 1px solid #29D884;
        background-color: #29D8841A;
        color: #29D884;
        font-size: 17px;
        font-weight: 600;
    }
}

.bg-rechazado{
    background-color: #FF3B63;
    height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 400 !important;
    font-size: 14px;
}
.bg-aprobado{
    background-color: #28D07B;
    height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 400 !important;
    font-size: 14px;
}
.bg-pendiente{
    background-color: #F5F5F5;
    height: 24px;
    display: flex;
    align-items: center;
    color: var(--color-general2);
    font-size: 400 !important;
    font-size: 14px;
}



@media (min-width: 300px) and (max-width: 985px){
    /* .info-credit{
        min-height: 60px !important;
    } */
    section{
        background-color: #FFFFFF !important;
        height: calc(100vh - 161px) !important;
        overflow: auto !important;
    }
    .title-mobile{
        display: flex !important;
        align-items: center !important;
    }
    .panel-left{
        height: auto !important;
        border: 0px solid !important;
    }
    .panel-right{
        height: auto !important;
    }
    .register-button{
        border-radius: 12px 12px 0px 0px;
        box-shadow: 0px 2px 6px #00000029;
        position: fixed;
        bottom: 0px;
        background-color: #FFFFFF;
        z-index: 1;
        div{
            height: 44px;
            width: 60%;
        }
    }
}
</style>