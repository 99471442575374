import { render, staticRenderFns } from "./canje.vue?vue&type=template&id=5c5db1bb&scoped=true&"
import script from "./canje.vue?vue&type=script&lang=js&"
export * from "./canje.vue?vue&type=script&lang=js&"
import style0 from "./canje.vue?vue&type=style&index=0&id=5c5db1bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c5db1bb",
  null
  
)

export default component.exports