import axios from 'axios';

const API = 'premios/canje';

const ENDPOINTS = {

    getHistorialPremios(){
        return axios.get(`${API}/historial`)
    },
    getComentariosLista(id_canje){
        return axios.get(`${API}/${id_canje}/lista-comentarios`)
    },
    getPremioDetalle(id_canje){
        return axios.get(`${API}/${id_canje}/detalle`)
    },
    cancelarPedido(id_canje){
        return axios.put(`${API}/${id_canje}/cancelar-canje`)
    },
    nuevoComentario( payload ){
        return axios.post(`${API}/post-comentario`, payload )
    },
};

export default ENDPOINTS;
