var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"px-xl-5 px-lg-5 px-md-4 px-sm-2 px-2 pt-4 custom-scroll overflow-auto",staticStyle:{"height":"calc(100vh - 86px)"}},[_c('div',{staticClass:"row mx-0"},[_c('i',{staticClass:"icon-back f-22 mt-1 cr-pointer",on:{"click":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"col px-2"},[_vm._m(0),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-xl col-lg col-md col-sm-12 col-12 pl-xl-3 pl-lg-3 pl-md-3 pl-sm-0 pl-0"},[_c('div',{staticClass:"row mx-0 mb-4"},[_vm._m(1),_c('div',{staticClass:"col-12 px-xl-3 px-lg-3 px-md-0 px-sm-0 px-0 mt-3"},[_c('p',{staticClass:"f-600 f-18 mt-2"},[_vm._v(" Descripción del evento ")]),_c('p',{staticClass:"f-14 mt-2"},[_vm._v(" Descripción del evento, este evento se realiza para que los "+_vm._s(_vm.$config.cliente)+" pueden ganar muchas gemas y monedas. Exploring the notifications modal with a focus on the simplicity of the interface, and UX writing as well. 🍔 ")])])])]),_c('div',{staticClass:"col"},[_vm._m(2),_vm._l((3),function(data,d1){return _c('div',{key:`compras-${d1}`,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"card-compra p-2 bg-white"},[_vm._m(3,true),_vm._m(4,true),_c('div',{staticClass:"row mx-0 mt-2 br-20 bg-fondo2",staticStyle:{"height":"5px"}},[_c('div',{staticClass:"h-100 br-20 bg-green",style:(`width:${50}%`)})])])])}),_vm._m(5),_vm._l((3),function(data,d){return _c('div',{key:`compras-ped-${d}`,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"card-compra p-2 bg-white"},[_vm._m(6,true),_vm._m(7,true),_c('div',{staticClass:"row mx-0 mt-2 br-20 bg-fondo2",staticStyle:{"height":"5px"}},[_c('div',{staticClass:"h-100 br-20 bg-green",style:(`width:${0}%`)})])])])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 title-category mb-4"},[_c('p',{staticClass:"col px-0 f-28 text-general f-600"},[_vm._v(" Misiones del evento ")]),_c('div',{staticClass:"col-12"}),_c('div',{staticClass:"like-bar my-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 px-0"},[_c('img',{staticClass:"br-8 obj-cover img-evento",attrs:{"src":"https://cdn.pixabay.com/photo/2016/08/03/09/03/universe-1566159__340.jpg","width":"596","height":"199"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 a-center mb-2"},[_c('img',{attrs:{"src":"/img/ilustraciones/gaming/dinero.png","width":"40","height":"40"}}),_c('p',{staticClass:"col-auto px-2"},[_c('span',{staticClass:"f-600"},[_vm._v("Compras")]),_vm._v(" (Dinero) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 a-center"},[_c('i',{staticClass:"icon-ok-circled-outline f-18 text-green"}),_c('div',{staticClass:"col-auto px-2 f-15"},[_vm._v(" $100.000 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"mn-pill bg-fondo f-600 mr-3"},[_c('img',{attrs:{"src":"/img/ilustraciones/gaming/i_moneda.svg","height":"20"}}),_c('span',{staticClass:"mx-2"},[_vm._v("2")])]),_c('div',{staticClass:"mn-pill bg-fondo f-600"},[_c('img',{attrs:{"src":"/img/ilustraciones/gaming/i_gema.svg","height":"20"}}),_c('span',{staticClass:"mx-2"},[_vm._v("1")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 a-center mb-2"},[_c('img',{attrs:{"src":"/img/ilustraciones/gaming/ventas_cantidad.png","width":"40","height":"40"}}),_c('p',{staticClass:"col-auto px-2"},[_c('span',{staticClass:"f-600"},[_vm._v("Compras")]),_vm._v(" (Cantidad de pedidos) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 a-center"},[_c('div',{staticClass:"col-auto px-2 f-15"},[_vm._v(" 0 / 100 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"mn-pill bg-fondo f-600 mr-3"},[_c('img',{attrs:{"src":"/img/ilustraciones/gaming/i_moneda.svg","height":"20"}}),_c('span',{staticClass:"mx-2"},[_vm._v("+2")])]),_c('div',{staticClass:"mn-pill bg-fondo f-600"},[_c('img',{attrs:{"src":"/img/ilustraciones/gaming/i_gema.svg","height":"20"}}),_c('span',{staticClass:"mx-2"},[_vm._v("+1")])])])
}]

export { render, staticRenderFns }