<template>
	<section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 75px)">
		<div class="box d-flex flex-column">
			<div id="sign-in-button" />
			<p class="title text-center pt-5">
				Verificar Código
			</p>
			<div class="row mx-0 j-center">
				<div class="col-8">
					<p class="text-general f-15 mt-3">
						Te enviamos un código al
					</p>
					<p class="f-17">
						<span class="f-500">+{{ telefono.indicador }}</span>
						<span class="text-green ml-2">{{ telefono.telefono }}</span>
					</p>
				</div>
			</div>
			<div class="row mx-0 mt-4 j-center">
				<div class="button-digits mx-1">
					<el-input ref="cod1" v-model.number="value1" class="w-100" maxlength="1" @input.self="next(2)" />
				</div>
				<div class="button-digits mx-1">
					<el-input ref="cod2" v-model.number="value2" class="w-100" maxlength="1" @input.self="next(3)"
						@keyup.native.delete="back(1)" />
				</div>
				<div class="button-digits mx-1">
					<el-input ref="cod3" v-model.number="value3" class="w-100" maxlength="1" @input.self="next(4)"
						@keyup.native.delete="back(2)" />
				</div>
				<div class="button-digits mx-1">
					<el-input ref="cod4" v-model.number="value4" class="w-100" maxlength="1" @input.self="next(5)"
						@keyup.native.delete="back(3)" />
				</div>
				<div class="button-digits mx-1">
					<el-input ref="cod5" v-model.number="value5" class="w-100" maxlength="1" @input.self="next(6)"
						@keyup.native.delete="back(4)" />
				</div>
				<div class="button-digits mx-1">
					<el-input ref="cod6" v-model.number="value6" class="w-100" maxlength="1"
						@keyup.native.delete="back(5)" />
				</div>
			</div>
			<div class="row mx-0 j-center my-2">
				<p class="col-8 text-general f-300 f-15">
					¿No te llegó nada? <span class="text-green f-500 ml-3 cr-pointer" @click="reenviarCodigo">Reenviar
						código</span>
				</p>
			</div>
			<div class="row mx-0 j-center">
				<div class="col-8 px-0">
					<div class="btn-general br-8" style="height:44px;" @click="verificarCodigo">
						Ingresar
					</div>
				</div>
				<div v-if="errorCodigo" class="col-8 f-14 text-general-red text-center mt-1">
					<small>El código ingresado no es válido</small>
				</div>
				<div v-if="nuevoCodigo" class="col-8 f-14 text-green text-center mt-1">
					<small>Se ha enviado un nuevo código</small>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Registro from '~/services/registro'
import Service from '~/services/auth';
import Cliente from '~/services/cliente/cliente'

export default {
	props: {
        telefono: {
            type: Object,
            default: {}
        }
    },
	data() {
		return {
			nuevoCodigo: false,
			errorCodigo: false,
			value1: '',
			value2: '',
			value3: '',
			value4: '',
			value5: '',
			value6: '',
		}
	},
	computed: {
		codigoCompleto() {
			return `${this.value1}${this.value2}${this.value3}${this.value4}${this.value5}${this.value6}`
		},
		validarCodigoCompleto() {
			return [this.value1, this.value2, this.value3, this.value4, this.value5, this.value6].every((el) => {
				return !_.isEmpty(`${el}`.trim())
			})
		},
	},
	methods: {
		async verificarCodigo() {
			try {
				if (this.validarCodigoCompleto) {
					const data = await Registro.verificarCodigo(this.codigoCompleto)
					if (data) {
						this.registrarUsuario();
					}
				}
			} catch (e) {
				this.errorCodigo = true;
				this.errorCatch(e)
			}
		},

		async registrarUsuario() {
			try {
				const payload = {
					telefono: this.telefono.telefono
				}

				const { data } = await Cliente.updateTelefono(payload);
				this.notificacion('', data.mensaje, "success");
				this.$router.push({ name: 'mi-perfil' });
			} catch (e) {
				this.errorCatch(e)
			}
		},

		async reenviarCodigo() {
			try {
				this.limpiarErrores();
				const numeroCelular = `+${this.telefono.indicador}${this.telefono.telefono}`;
				const data = await Registro.enviarCodigoRegistro(numeroCelular);
				if(data){
				    this.nuevoCodigo = true;
				    this.limpiarCodigo();
				}
			} catch (e) {
				this.errorCatch(e)
			} finally {
				setTimeout(() => {
					this.nuevoCodigo = false;
				}, 5000)
			}
		},

		next(number) {
			this.$nextTick(function () {
				if (this.$refs[`cod${number - 1}`].value != "" && number > 1) {
					this.$refs[`cod${number}`].focus()
				}
			})
		},

		back(number) {
			if (this.$refs[`cod${number}`].value) {
				this.$nextTick(function () {
					this.$refs[`cod${number}`].focus()
				})
			}
		},

		limpiarCodigo() {
			this.value1 = '';
			this.value2 = '';
			this.value3 = '';
			this.value4 = '';
			this.value5 = '';
			this.value6 = '';
		},

		limpiarErrores() {
			this.errorCodigo = false;
			this.nuevoCodigo = false;
		},
	}
}
</script>
<style lang="scss" scoped>
.box {
	width: 491px;
	height: 50vh;
	border-radius: 12px;
	box-shadow: 0px 1px 4px #00000014;

	.title {
		font-size: 37px !important;
		font-weight: 600;
	}

	.button-digits {
		width: 50px;
		height: 50px;
	}
}

@media (min-height: 300px) and (max-height: 800px) {
	.box {
		height: 65vh !important;
	}
}

.text-general-red {
	color: red;
}

/* @media (min-height: 800px) and (max-height: 1366px) {
    .box{
        height: 38vh !important;
    }
} */</style>