<template>
    <section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 76px)">
        <div class="box px-4">
            <div class="overflow-auto custom-scroll" style="height:calc(100vh - 257px)">
                <p class="text-general f-18 text-center my-2 f-500">
                    Soporte 123456
                </p>
                <div class="row mx-0 a-center my-3">
                    <div class="col px-0">
                        <hr class="border" />
                    </div>
                    <div class="col-auto text-general2 f-300 f-15">
                        15 Julio 2020
                    </div>
                    <div class="col px-0">
                        <hr class="border" />
                    </div>
                </div>
                <p class="text-general f-18 text-center my-2 f-500">
                    Gracias por comunicarte
                </p>
                <div class="row mx-0 justify-content-start">
                    <div class="chat-bubble bg-light-f5 py-2 px-3">
                        <div class="chat-bubble-arrow-left" />
                        <!-- <div class="chat-bubble-arrow-right" /> -->
                        <p v-if="false" class="f-15">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente cupiditate quia error aliquam quas deserunt labore, doloremque animi quae atque deleniti tenetur, aspernatur neque id omnis ipsa placeat quidem. Officia.
                        </p>
                        <img v-else :src="url_image" class="obj-cover br-3" style="max-width:349px;height:160px;" />
                        <p class="text-general2 f-15 f-300 text-right mt-1">11:02 a.m.</p>
                    </div>
                </div>
            </div>
            <div class="position-relative" style="height:75px;">
                <div class="w-100 d-flex mx-0 a-center position-absolute" style="bottom:0px">
                    <div class="col px-0 position-relative bg-light-f5 border br-6">
                        <el-input v-model="mensaje" :autosize="{minRows: 0, maxRows: 3}" type="textarea" placeholder="Mensaje" class="w-100 chat-input" />
                        <i class="icon-house position-absolute f-18" style="top: 1px;right: 12px;" />
                    </div>
                    <div class="col-auto pr-0 cr-pointer">
                        <i class="icon-send-message f-18 text-general" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            mensaje: '',
            url_image: 'https://depor.com/resizer/QGd_mZeULbIo6FFTrHJgGzg_3ek=/580x330/smart/filters:format(jpeg):quality(75)/cloudfront-us-east-1.images.arcpublishing.com/elcomercio/T53XXVHH7FF6BG7MD3CBFN6TZY.jpg'
        }
    }
}
</script>
<style lang="scss" scoped>
.box{
    width: 801px;
    height: 84vh;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
}
</style>