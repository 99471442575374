<template>
    <section class="row mx-0">
        <cargando v-if="cargando" />
        <div class="panel-categories overflow-auto scroll-none">
            <div class="row mx-0 j-center">
                <div class="col-10 d-middle border-bottom px-1 py-3">
                    <span v-if="moneda_usada == true" class="text-gris2 f-14">
                        Usando gemas
                    </span>
                    <span v-else class="text-gris2 f-14">
                        Usando monedas
                    </span>
                    <div class="manual-switch-coins ml-auto d-flex cr-pointer" @click="moneda_usada = !moneda_usada">
                        <div class="" :class="`inside${moneda_usada ? '-active' : '-inactive'} ${ moneda_usada ? 'ml-auto' : 'mr-auto' }`">
                            <img :src="`/img/ilustraciones/gaming/${moneda_usada ? 'i_gema' : 'i_moneda'}.svg` " />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-3 j-center">
                <div class="col-10 f-18 f-600 py-3">
                    Categorías
                </div>
            </div>
            <div class="row mx-0 j-center mb-3 cr-pointer" @click="verTodos()">
                <div class="col-10 px-0 border-bottom d-middle pb-3">
                    <div :class="`${active_category == 1 ? 'bg-general' : ''} br-20`" style="width:8px;height:40px;" />
                    <div class="col px-2 f-15">
                        Todos
                    </div>
                    <i class="icon-right-open f-15 text-gris2" />
                </div>
            </div>
            <div v-for="(data, idx) in categorias" :key="idx" class="row mx-0 j-center mb-3 cr-pointer" @click="categoriaIr(data.productos)">
                <div class="col-10 px-0 border-bottom d-middle pb-3">
                    <div :class="`${active_category == data ? 'bg-general' : ''} br-20`" style="width:8px;height:40px;" />
                    <div class="col px-2 f-15">
                        {{ data.nombre }}
                    </div>
                    <i class="icon-right-open f-15 text-gris2" />
                </div>
            </div>
        </div>
        <component :is="componentName" :moneda="moneda_usada" :premios-lista="premiosLista" @verCategoria="categoriaIr" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Premios from '~/services/gaming/gamingPremios'
export default {
    middleware:'menu-check',
    components: {
        listarCategorias: () => import('./components/listar'),
        categoriaEspecifica: () => import('./components/categoriaEspecifica')
    },
    data(){
        return {
            cargando: false,
            premios: [],
            premiosLista: [],
            value2: true,
            moneda_usada: true,
            componentName: 'listarCategorias',
            active_category: null,
            categorias:[]
        }
    },
    computed:{
        ...mapGetters({
            usuario: 'auth/obtenerUsuario',
        })
    },
    mounted(){
        this.premiosListar()
    },
    methods: {
        categoriaIr(item){

            this.premiosLista = item
            this.componentName = 'categoriaEspecifica'
        },
        async premiosListar(){
            try {
                this.cargando = true;

                let params = {
                    id_tienda : this.usuario.tienda.id
                }
                const { data } = await Premios.getListaPremios(params);
                this.premiosLista = data.premiosPorCategoria;
                this.categorias = data.premiosPorCategoria;

            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }    
        },
        verTodos(){
            this.premiosLista = this.categorias,
            this.componentName= 'listarCategorias';
        }
    }
}
</script>
<style lang="scss" scoped>
.panel-categories{
  height: calc(100vh - 80px);
  width: 245px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000014;
  @media (min-width: 300px) and (max-width: 1000px) {
      //display: none !important;
  }
}

@media (min-width: 300px) and (max-width: 1024px){
    .panel-categories{
    height: calc(100vh - 98px);
  }
}
</style>