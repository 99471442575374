<template>
    <section class="bg-fondo px-4 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 76px)">
        <div class="row mx-0">
            <i class="icon-back mt-2 f-20 cr-pointer" @click="$router.back()" />
            <div class="col">
                <div class="row mx-0 mb-4">
                    <p class="col-12 px-0 text-black f-600 f-28">
                        Historial de consultas
                    </p>
                    <div class="like-bar  mt-1" />
                </div>
                <div v-for="(con, c) in consultas" :key="c" class="row mx-0">
                    <div class="card-consulta cr-pointer p-2 bg-white br-10 shadow-14 mb-3">
                        <div class="row mx-0 mt-1">
                            <div v-if="con.estado == 1" class="active-circle mt-1" />
                            <i v-else class="icon-ok-circled-outline text-gris f-15" />
                            <div class="col px-2 f-15">
                                <p class="f-600"> Consulta No. 4511 </p>
                                <div class="d-flex text-gris2 f-500 f-15 mt-1">
                                    <span>15 Julio 2020</span>
                                    <span class="ml-auto"> {{ con.estado == 1 ? 'Abierto' : 'Finalizado' }} </span>
                                </div>
                            </div>
                            <div class="col-2 d-middle text-gris2">
                                <i class="icon-chat" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            consultas: [
                {
                    no: '4511',
                    fecha: '15 Julio 2020',
                    estado: 1,
                    chat: true
                },
                {
                    no: '4510',
                    fecha: '15 Julio 2020',
                    estado: 2
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.card-consulta{
    height: 73px;
    min-width: 358px;
    max-width: 358px;
    .active-circle{
        width: 16px;
        height: 16px;
        background-color: #29D884;
        border-radius: 50%;
    }
}
</style>