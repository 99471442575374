<template>
    <section class="px-xl-5 px-lg-5 px-md-4 px-sm-2 px-2 pt-4 custom-scroll overflow-auto" style="height:calc(100vh - 86px)">
        <div class="row mx-0">
            <i class="icon-back f-22 mt-1 cr-pointer" @click="$router.back()" />
            <div class="col px-2">
                <div class="row mx-0 title-category mb-4">
                    <p class="col px-0 f-28 text-general f-600">
                        Misiones del evento
                    </p>
                    <div class="col-12" />
                    <div class="like-bar my-2" />
                </div>
                <div class="row mx-0">
                    <div class="col-xl col-lg col-md col-sm-12 col-12 pl-xl-3 pl-lg-3 pl-md-3 pl-sm-0 pl-0">
                        <div class="row mx-0 mb-4">
                            <div class="col-12 px-0">
                                <img src="https://cdn.pixabay.com/photo/2016/08/03/09/03/universe-1566159__340.jpg" class="br-8 obj-cover img-evento" width="596" height="199" />
                            </div>
                            <div class="col-12 px-xl-3 px-lg-3 px-md-0 px-sm-0 px-0 mt-3">
                                <p class="f-600 f-18 mt-2">
                                    Descripción del evento
                                </p>
                                <p class="f-14 mt-2">
                                    Descripción del evento, este evento se realiza para que los {{ $config.cliente }} pueden ganar muchas gemas y monedas. Exploring the notifications modal with a focus on the simplicity of the interface, and UX writing as well. 🍔
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row mx-0 a-center mb-2">
                            <img src="/img/ilustraciones/gaming/dinero.png" width="40" height="40" />
                            <p class="col-auto px-2">
                                <span class="f-600">Compras</span> (Dinero)
                            </p>
                        </div>
                        <div v-for="(data, d1) in 3" :key="`compras-${d1}`" class="row mx-0 mb-3">
                            <div class="card-compra p-2 bg-white">
                                <div class="row mx-0 a-center">
                                    <i class="icon-ok-circled-outline f-18 text-green" />
                                    <div class="col-auto px-2 f-15">
                                        $100.000
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="mn-pill bg-fondo f-600 mr-3">
                                        <img src="/img/ilustraciones/gaming/i_moneda.svg" height="20" />
                                        <span class="mx-2">2</span>
                                    </div>
                                    <div class="mn-pill bg-fondo f-600">
                                        <img src="/img/ilustraciones/gaming/i_gema.svg" height="20" />
                                        <span class="mx-2">1</span>
                                    </div>
                                </div>
                                <div class="row mx-0 mt-2 br-20 bg-fondo2" style="height:5px;">
                                    <div class="h-100 br-20 bg-green" :style="`width:${50}%`" />
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 a-center mb-2">
                            <img src="/img/ilustraciones/gaming/ventas_cantidad.png" width="40" height="40" />
                            <p class="col-auto px-2">
                                <span class="f-600">Compras</span> (Cantidad de pedidos)
                            </p>
                        </div>
                        <div v-for="(data, d) in 3" :key="`compras-ped-${d}`" class="row mx-0 mb-3">
                            <div class="card-compra p-2 bg-white">
                                <div class="row mx-0 a-center">
                                    <div class="col-auto px-2 f-15">
                                        0 / 100
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="mn-pill bg-fondo f-600 mr-3">
                                        <img src="/img/ilustraciones/gaming/i_moneda.svg" height="20" />
                                        <span class="mx-2">+2</span>
                                    </div>
                                    <div class="mn-pill bg-fondo f-600">
                                        <img src="/img/ilustraciones/gaming/i_gema.svg" height="20" />
                                        <span class="mx-2">+1</span>
                                    </div>
                                </div>
                                <div class="row mx-0 mt-2 br-20 bg-fondo2" style="height:5px;">
                                    <div class="h-100 br-20 bg-green" :style="`width:${0}%`" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    middleware:'menu-check',
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">
.card-compra{
    width: 285px;
    min-height: 72px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
}

@media (min-width: 300px) and (max-width: 815px) {
    .img-evento{
        width: 100% !important;
    }
}

</style>