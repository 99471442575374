import axios from 'axios';

const API = 'cliente/creditos';

const ENDPOINTS = {
    getCreditosVigentes(){
        return axios.get(`${API}`)
    },
    getCreditosHistorial(){
        return axios.get(`${API}/historial`)
    },
    verCreditosDetalle(id_pedido){
        return axios.get(`${API}/${id_pedido}/detalle`)
    },
};

export default ENDPOINTS;